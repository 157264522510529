/*
@File: Rania LAKOUD 2023

* This file contains the styling for the actual tepmlate, this
is the file you need to edit to change the look of the
tepmlate.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Navbar CSS
** - Main Banner CSS
** - About CSS
** - Why Choose Us CSS
** - CTA CSS
** - Speakers CSS
** - Schedule CSS
** - FunFacts CSS
** - Pricing CSS
** - Partner CSS
** - Blog CSS
** - Buy Tickets CSS
** - Subscribe CSS
** - Page Title CSS
** - Login CSS
** - Signup CSS
** - 404 Error CSS
** - FAQ CSS
** - Coming Soon CSS
** - Pagination CSS
** - Blog Details CSS
** - Sidebar CSS
** - Contact Us CSS
** - Footer CSS
** - Back To Top CSS
** - why chose us homr page css
** - Why chose use about page CSS
** - language switch CSS

*/
/*================================================
Default CSS
=================================================*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');


:root {
    --main-color: white;
    --scond-color: #ff9900;
    --text-color: #597931;
    --therd-color: #ff9900;
    --green-color: #bb9d81;
    --yellow-color: #5a8c55;
    scroll-behavior: initial;
}

/* Personnaliser la couleur du curseur (thumb) de la barre de défilement */
::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Couleur de fond du curseur */
    /* Coins arrondis du curseur (facultatif) */
}

/* Personnaliser la couleur de fond de la barre de défilement */
::-webkit-scrollbar-track {
    background-color: transparent;
    /* Couleur de fond de la barre de défilement */
}

/* Optionnel : Personnaliser la couleur de la bordure de la barre de défilement */
::-webkit-scrollbar {
    width: 0.2px;
    /* Largeur de la barre de défilement */
}

.logo-orgonasation {
    width: 100px;
    margin-right: 0;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
}

.video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
}

body {
    font-family: 'Roboto', sans-serif; 

    padding: 0;
    margin: 0;
    font-size: 15px;
}

.why-choose-img {}

a {
    display: inline-block;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-decoration: none;
}

a:hover,
a:focus {
    text-decoration: none;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    vertical-align: middle;
}

.ptb-120 {
    padding-top: 120px;
    padding-bottom: 120px;
}

.ptb-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

img {
    max-width: 100%;
}

p {
    line-height: 1.9;
    color: var(--text-color);
    margin-bottom: 15px;
}

p:last-child {
    margin-bottom: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    
}

.text-right {
    text-align: end;
}

.text-left {
    text-align: start;
}

.form-group {
    margin-bottom: 30px;
}

textarea.form-control {
    min-height: auto !important;
    height: auto;
}

label {
    margin-bottom: 10px;
}

/*btn btn-primary*/
.btn.arrow-button {
    position: relative;
    display: inline-flex;
    align-items: center;
    /* Pour aligner le texte et la flèche */
    padding-right: 20px;
    /* Ajouter un peu de padding à droite pour la flèche */
}

.btn.arrow-button::after {
    padding-inline: 5px;
    content: " >> ";
    /* Utilisez un caractère flèche ou une image */
    font-size: 1rem;
    /* Ajustez la taille de la flèche */
    margin-left: 10px;
    /* Espace entre le texte et la flèche */
    transition: margin-left 0.3s;
    /* Transition pour l'animation */
}

/* Ajoutez une animation de la flèche lors du survol */
.btn.arrow-button:hover::after {
    margin-left: 15px;
    /* Déplacer légèrement la flèche vers la droite */
}

.btn-primary {
    color: #ffffff;
    background: var(--main-color);
    position: relative;
    z-index: 1;
}

.lang {
    padding: 0.6rem;
}

.btn-primary::before,
.btn-primary::after {
    content: "";
    position: absolute;
    top: 0;
    width: 51%;
    background: #315ca7;
    height: 100%;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.btn-primary::before {
    left: 0;
}

.btn-primary::after {
    right: 0;
}

.btn-primary:hover::before,
.btn-primary:hover::after,
.btn-primary:focus::before,
.btn-primary:focus::after {
    width: 0;
}

.btn-primary.focus,
.btn-primary:focus {
    -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus {
    background: var(--main-color);
    color: var(--text-color);
    -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
    background: var(--main-color);
    color: #ffffff;
}

.btn-primary.disabled,
.btn-primary:disabled {
    opacity: 1;
    background: var(--main-color);
}

.btn-secondary {
    color: #ffffff;
    background: var(--main-color);
    position: relative;
    z-index: 1;
}

.btn-secondary::before,
.btn-secondary::after {
    content: "";
    position: absolute;
    top: 0;
    width: 51%;
    background: var(--therd-color);
    height: 100%;
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.btn-secondary::before {
    left: 0;
}

.btn-secondary::after {
    right: 0;
}

.btn-secondary:hover::before,
.btn-secondary:hover::after,
.btn-secondary:focus::before,
.btn-secondary:focus::after {
    width: 0;
}

.btn-secondary.focus,
.btn-secondary:focus {
    -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    color: #ffffff;
}

.btn-secondary:hover,
.btn-secondary:focus {
    background: var(--main-color);
    color: #ffffff;
    -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
    -webkit-box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
    box-shadow: 0 13px 27px 0 rgba(245, 78, 162, 0.25);
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active {
    background: var(--main-color);
    color: #ffffff;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    opacity: 1;
    background: var(--main-color);
}

/*section-title*/
.section-title {
    position: relative;
    margin-bottom: 60px;
    z-index: 1;
}

.section-title span {
    color: var(--scond-color);
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
}

.section-title h2 {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.section-title h2 b {
    background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(80%, rgba(0, 0, 0, 0)),
            color-stop(80%, var(--scond-color)));
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 80%,
            var(--scond-color) 80%);
    font-weight: unset;
    background-size: 100px 1.07em !important;
}

.section-title .btn {
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -18px;
}

.section-title .bg-title {
    color: rgba(73, 57, 231, 0.05);
    font-weight: 700;
    font-size: 140px;
    line-height: 120px;
    position: absolute;
    top: 0;
    right: 35%;
    text-transform: uppercase;
    letter-spacing: 1px;
    z-index: -1;
    margin-top: -25px;
}
.section-title {
    text-align: center; /* Center the content inside section-title */
}

.bar-line {
    display: inline-block; /* Makes sure the bar-line is only as wide as its content */
    position: relative;
}

.section-title .bar {
    position: relative;
    width: 25px;
    height: 4px;
    background: var(--scond-color);
    display: block;
}

.section-title .bar::after,
.section-title .bar::before {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    content: "";
}

.section-title .bar::after {
    width: 25px;
    background: black;
    right: -29px;
}

.section-title .bar::before {
    background: black;
    width: 30px;
    right: -63px;
}
.view-details{
    display: flex;
    justify-content: end;
   margin-bottom: 15px;

}
.btn{
    background-color:#ff9900;
    color: white;
}
/*owl-carousel-btn*/
.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 0;
    line-height: 0.01;
}

.owl-theme .owl-dots .owl-dot span {
    width: 15px;
    height: 15px;
    margin: 0 4px;
    background: transparent;
    border: 1px solid #dfdfdf;
    display: block;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
    position: relative;
}

.owl-theme .owl-dots .owl-dot:hover,
.owl-theme .owl-dots .owl-dot:focus {
    outline: 0;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
}

.owl-theme .owl-dots .owl-dot:hover span,
.owl-theme .owl-dots .owl-dot:focus span {
    background: var(--therd-color);
    border-color: var(--therd-color);
}

.owl-theme .owl-dots .owl-dot.active span {
    background: var(--scond-color);
    border-color: var(--scond-color);
}

/*form-control*/
.form-control {
    height: 50px;
    padding: 15px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #eeeeee;
    border-radius: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.form-control:hover,
.form-control:focus,
.form-control.active {
    -webkit-box-shadow: unset;
    box-shadow: unset;
    border-color: var(--scond-color);
}

/*================================================
Preloader CSS
=================================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden;
    background: var(--main-color);
}

.preloader .loader {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 110px;
    height: 110px;
    margin: -80px 0 0 -75px;
    border-radius: 50%;
    border: 7px solid transparent;
    border-top-color: var(--scond-color);
    -webkit-animation: spin 1.7s linear infinite;
    animation: spin 1.7s linear infinite;
    z-index: 11;
}

.preloader .loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 7px solid transparent;
    border-top-color: var(--yellow-color);
    -webkit-animation: spin-reverse 0.6s linear infinite;
    animation: spin-reverse 0.6s linear infinite;
}

.preloader .loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 7px solid transparent;
    border-top-color: var(--therd-color);
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spin-reverse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes spin-reverse {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

/*================================================
Navbar CSS
=================================================*/
.navbar-brand {
    width: 5rem;
}

.navbar-brand img {
    object-fit: contain;
}

.is-sticky .item-bg33 {
    background-position: center;
    background-attachment: local;
    background-repeat: no-repeat;
    background-size: 85%;
    background-image: url("../images/LOGO FUTURE VISIONS 2023-25.jpg");
    height: 6rem;
    width: 15%;
}

@media (max-width: 767px) {
    .elkevent-nav .item-bg33 {
        background-position: center;
        background-attachment: local;
        background-repeat: no-repeat;
        background-size: 85%;
        background-image: url("../images/FVB-03 (1).png");
        height: 8rem;
        width: 40%;
    }

    .is-sticky .item-bg33 {
        background-position: center;
        background-attachment: local;
        background-repeat: no-repeat;
        background-size: 85%;
        background-image: url("../images/LOGO FUTURE VISIONS 2023-25.jpg");
        height: 8rem;
        width: 40%;
    }
}

.elkevent-mobile-nav {
    display: none;
}

.elkevent-mobile-nav.mean-container .mean-nav ul li a.active {
    color: var(--scond-color);
}

.elkevent-nav {
    z-index: 999;

    background-color: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    height: auto;
}

.elkevent-nav nav ul,
.navfooter nav ul {
    list-style-type: none;
}

.elkevent-nav nav .navbar-nav .nav-item {
    position: relative;
}

.navfooter nav .navbar-nav .nav-item {}

.navfooter nav {}

.elkevent-nav nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 1.3rem;
    color: black;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.navfooter .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 1.1rem;
    color: black;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.show .nav-item a {
    font-weight: 500;
    font-size: 1.3rem;
    /* text-transform: uppercase; */
    color: black;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.toggle-button {
    display: none;
    cursor: pointer;
    padding-top: 5px;
}

.bar {
    display: block;
    width: 35px;
    height: 4px;
    background-color: #fff;
    margin-bottom: 6px;
}

.navfooter .bar {
    display: block;
    width: 35px;
    height: 4px;
    background-color: black;
    margin-bottom: 6px;
}

.is-sticky .bar {
    display: block;
    width: 35px;
    height: 4px;
    background-color: black;
    margin-bottom: 6px;
}

@media (max-width: 768px) {
    .toggle-button {
        display: block;
    }
}

.show>ul>.nav-item a:first-child {
    color: black;
}

.is-sticky nav .navbar-nav .nav-item a {
    font-weight: 500;
    font-size: 16px;
    /* text-transform: uppercase; */
    color: black;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 15px;
    margin-right: 15px;
}

.elkevent-nav nav .navbar-nav .nav-item a:hover,
.elkevent-nav nav .navbar-nav .nav-item a:focus,
.elkevent-nav nav .navbar-nav .nav-item a.active {
    color: var(--scond-color);
}

.elkevent-nav nav .navbar-nav .nav-item:hover a {
    color: var(--scond-color);
}

.navfooter nav .navbar-nav .nav-item a:hover,
.navfooter nav .navbar-nav .nav-item a:focus,
.navfooter nav .navbar-nav .nav-item a.active {
    color: var(--scond-color);
}

.navfooter nav .navbar-nav .nav-item:hover a {
    color: var(--scond-color);
}

.show {
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--main-color) !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
    color: var(--scond-color);
    background-color: #e4e4e4b1;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu {
    position: absolute;
    left: 220px;
    top: 0;
    opacity: 0 !important;
    visibility: hidden !important;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a {
    color: black;
    text-transform: capitalize;
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:hover,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a:focus,
.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li .dropdown-menu li a.active {
    color: var(--scond-color);
}

.elkevent-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
    color: var(--scond-color);
}

.my-navbar {
    align-items: center;
}

.elkevent-nav nav .navbar-nav .nav-item:hover ul {
    opacity: 1;
    visibility: visible;
    top: 100%;
}

.elkevent-nav nav .others-option {
    margin-left: 13px;
}

.elkevent-nav.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 5px 0;
    z-index: 999;
    -webkit-box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    background-color: var(--main-color) !important;
    -webkit-animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

/*================================================
Main Banner CSS
=================================================*/

.main-banner {
    height: 100vh;
    position: relative;
    z-index: 1;
    /* background: url("../images/bg6.PNG"); */
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    /* background-position: center center; */
    /* background-size: cover;
  background-repeat: no-repeat; */
}

.main-banner::before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    opacity: 0.77;
}

.imag-landing {
    width: 100%;
    border-radius: 20px;
    position: relative;
    /* Pour positionner les images filles */
    clip-path: polygon(25% 0%,
            75% 0%,
            100% 100%,
            0% 100%);
    /* Forme de trapèze */
}

.imag-rotation-1 {
    border-radius: 20px;
    width: 55%;

    position: absolute;
    top: 10px;
    left: 10px;
    transform: rotate(-20deg);
    /* Rotation de la première image superposée */
}

.imag-rotation-2 {
    border-radius: 20px;
    width: 55%;

    position: absolute;
    top: 20px;
    left: 50px;
    transform: rotate(25deg);
    /* Rotation de la deuxième image superposée */
}

.slideshow-banner::before {
    z-index: 1;
}

.slideshow-banner .main-banner-content::before {
    display: none;
}

.slideshow-banner .slideshow {
    z-index: -1;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.slideshow-banner .slideshow li span {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 0;
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: imageAnimation 24s linear infinite 0s;
    animation: imageAnimation 24s linear infinite 0s;
}

.slideshow-banner .slideshow li:nth-child(1) span {
    /* background-image: url("../images/future-visions-ack.jpg"); */
}

.slideshow-banner .slideshow li:nth-child(2) span {
    /* background-image: url("../images/future-visions-ack.jpg"); */
    -webkit-animation-delay: 6s;
    animation-delay: 6s;
}

.slideshow-banner .slideshow li:nth-child(3) span {
    /* background-image: url("../images/future-visions-ack.jpg"); */
    -webkit-animation-delay: 12s;
    animation-delay: 12s;
}

.slideshow-banner .slideshow li:nth-child(4) span {
    /* background-image: url("../images/future-visions-ack.jpg"); */
    -webkit-animation-delay: 18s;
    animation-delay: 18s;
}

.slideshow-banner .event-countdown {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: left;
}

.slideshow-banner .event-countdown #days {
    margin-left: 0;
}

.video-banner {
    overflow: hidden;
}

.video-banner .video-background {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    height: auto;
    min-height: 100%;
    min-width: 100%;
    z-index: -2;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    /* Increase brightness to 150% */
    z-index: -1;
    /* Met la vidéo en arrière-plan */
}

/* .page-title-area::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--therd-color);
    opacity: .88;
  } */
.item-bg1 {
    /* background-image: url("../images/future-visions-ack.jpg"); */
}

.item-bg2 {
    background-image: url("../images/copy-space-childrens-reading (1).png");
    background-size: cover;
    
    /* background-attachment: fixed; */
}

.item-bg3 {
    /* background-image: url("../images/slider-bg1.jpg");  */
}

.item-bg4 {
    /* background-image: url("../images/slider-bg2.jpg"); */
}

.item-bg5 {
    /* background-image: url("../images/slider-bg3.jpg"); */
}

.main-banner-content {
    max-width: 850px;
    position: relative;
    z-index: 1;
}

.main-banner-content::before {
    display: none;

    content: "";
    position: absolute;
    top: -75px;
    width: 100%;
    max-width: 380px;
    text-align: center;
    height: 500px;
    left: 18%;
    margin-left: -190px;
    z-index: -1;
    border: 18px solid rgba(104, 89, 222, 0.24);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0 60px 40px -40px rgba(0, 0, 0, 0.3);
    box-shadow: 0 60px 40px -40px rgba(0, 0, 0, 0.3);
}

.main-banner-content h1 {
    -webkit-background-clip: text;
    /* Webkit prefix for Safari and Chrome */
    color: transparent;
    margin: 0;
    font-weight: 900;
    font-size: 400%;
}

/* .main-banner-content h1 span {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(80%, rgba(0, 0, 0, 0)), color-stop(80%, var(--scond-color)));
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, var(--scond-color) 80%);
  background-size: 100px 1.2em !important;
} */

/* .main-banner-content h1 b:nth-of-type(1) {
  color: var(--yellow-color);
}

.main-banner-content h1 b:nth-of-type(2) {
  color: var(--scond-color);
}

.main-banner-content h1 b:nth-of-type(3) {
  color: #ffffff;
  -webkit-animation: colorchange 20s;
          animation: colorchange 20s;
}

.main-banner-content h1 b:nth-of-type(4) {
  color: var(--therd-color);
} */

.main-banner-content ul {
    padding: 0;
    margin: 25px 0 40px;
    list-style-type: none;
}

.main-banner-content ul li {
    color: #fff;
    margin-right: 20px;
    font-size: 17px;
    display: inline-block;
}

.main-banner-content ul li i {
    margin-right: 3px;
    color: var(--scond-color);
}

.main-banner-content ul li:last-child {
    margin-right: 0;
}

.main-banner-content p {
    color: var(--yellow-color);
    font-size: 22px;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.main-banner-content p span {
    color: var(--therd-color);
}

/* .main-banner-content .button-box a {
  display: inline-block;
} */

/* .main-banner-content .button-box .video-btn {
  margin-left: 30px;
  color:  var(--green-color);
  font-weight: 500;
} */
.main-banner-content .button-box p {
    margin-left: 30px;

    font-weight: 700;
    color: var(--main-color);
    font-size: 22px;
    letter-spacing: 1px;
    margin-bottom: 10px;
}

.main-banner-content .button-box .video-btn i {
    width: 50px;
    display: inline-block;
    height: 50px;
    line-height: 51px;
    text-align: center;
    background-color: white;
    border-radius: 50%;
    font-size: 17px;
    margin-right: 8px;
    z-index: 1;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.main-banner-content .button-box .video-btn i::after {
    z-index: -1;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    -webkit-animation: ripple 1.6s ease-out infinite;
    animation: ripple 1.6s ease-out infinite;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
    opacity: 0;
    border-radius: 50%;
    background: rgba(51, 102, 204, 0.5);
}

.main-banner-content .button-box .video-btn:hover,
.main-banner-content .button-box .video-btn:focus {
    color: var(--therd-color);
}

.main-banner-content .button-box .video-btn:hover i,
.main-banner-content .button-box .video-btn:focus i {
    background-color: var(--scond-color);
    color: #ffffff;
}

.main-banner-content.banner-content-center {
    margin: 150px 50px 5px 40px;
}

.countdown-area {
    padding: 50px 0;
    background: var(--green-color);
}

.countdown-area .event-countdown {
    position: relative;
    right: 0;
    bottom: 0;
    text-align: center;
}

.countdown-area .event-countdown #timer div {
    margin: 0 55px;
}

.countdown-area .event-countdown #timer div#seconds {
    color: var(--scond-color);
}

.event-countdown {
    position: absolute;
    right: 10%;
    bottom: 20px;
    text-align: right;
}

.event-countdown #timer div {
    display: inline-block;
    font-size: 55px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 35px;
    position: relative;
}

.event-countdown #timer div#days {
    color: var(--scond-color);
}

.event-countdown #timer div#hours {
    color: var(--therd-color);
}

.event-countdown #timer div#hours span {
    right: -50px;
    margin-top: -12px;
}

.event-countdown #timer div#minutes {
    color: #ffffff;
}

.event-countdown #timer div#minutes span {
    right: -55px;
    margin-top: -12px;
}

.event-countdown #timer div#seconds {
    color: var(--yellow-color);
}

.event-countdown #timer div#seconds span {
    right: -60px;
    margin-top: -12px;
}

.event-countdown #timer span {
    display: block;
    color: #ffffff;
    margin-top: 0;
    font-size: 14px;
    font-weight: 500;
    position: absolute;
    right: -40px;
    top: 50%;
    text-transform: uppercase;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    margin-top: -10px;
    letter-spacing: 2px;
}

.countdown1 {
    bottom: 15px !important;
}

.countdown1 #timer div {
    font-size: 60px;
}

.countdown1 #timer span {
    display: inline-block;
    font-size: 14px;
    position: initial;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
}

@-webkit-keyframes ripple {

    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

@keyframes ripple {

    0%,
    35% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }

    50% {
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        opacity: 0.8;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(2);
        transform: scale(2);
    }
}

/* .shape1 {
 
  left: 70px;
  bottom: 70px;

  z-index: -1;
  position: absolute;
  width: 90px;
  height:90px;
 
  border-radius: 50%;

  background: var(--scond-color);
  opacity: 0.3;
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape2 {

  right: 70px;
  bottom:390px;

  position: absolute;
  width:50px;
  height: 50px;

  

  border: 1px solid #a0a0a0;
  opacity: 0.3;
  transform: rotate(-45deg);
  
  
  -webkit-animation: movebounce 1s linear infinite;
  animation: movebounce 5s linear infinite;
}

.shape3 {
  position: absolute;
  left: 70px;
  top: 70px;
  z-index: -1;
  opacity: .6;
}

.shape4 {
  position: absolute;
  right: 100px;
  top: 100px;
  z-index: -1;
  opacity: .6;
  -webkit-animation: movebounce 5s linear infinite;
          animation: movebounce 5s linear infinite;
} */

.rotateme {
    -webkit-animation-name: rotateme;
    animation-name: rotateme;
    -webkit-animation-duration: 40s;
    animation-duration: 40s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotateme {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes movebounce {
    0% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }

    50% {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }

    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@-webkit-keyframes colorchange {
    0% {
        color: var(--scond-color);
    }

    25% {
        color: #ffffff;
    }

    50% {
        color: red;
    }

    75% {
        color: var(--therd-color);
    }

    0% {
        color: var(--yellow-color);
    }
}

@keyframes colorchange {
    0% {
        color: var(--scond-color);
    }

    25% {
        color: #ffffff;
    }

    50% {
        color: red;
    }

    75% {
        color: var(--therd-color);
    }

    0% {
        color: var(--yellow-color);
    }
}

.home-slides.owl-theme .owl-nav {
    margin-top: 0;
    line-height: 0.01;
}

.home-slides.owl-theme .owl-nav [class*="owl-"] {
    position: absolute;
    left: 10px;
    top: 50%;
 
    line-height: 50px;
    background-color: var(--therd-color);
    border-radius: 0;
    color: #ffffff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    opacity: 0.7;
    margin-top: -50px;
    padding: 0 !important;
    font-size: 25px;
}

.home-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: 10px;
}

.home-slides.owl-theme .owl-nav [class*="owl-"]:hover {
    background-color: var(--scond-color);
    color: #ffffff;
    opacity: 1;
    outline: 0;
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

/*================================================
About CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.about-area.bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about-content span {
    color: var(--scond-color);
    letter-spacing: 1px;
    font-size: 16px;
    text-transform: uppercase;
    display: block;
}
.about-section-title{
    text-align: center;  color:#333333;

}
.about-section-title h2 {
 
    font-size: 40px;
    text-transform: uppercase;

 
}

.line{
    border: 1px solid var(--scond-color);
    width: 50%;
    margin: auto;
}

.about-content h2 b {
    background: -webkit-gradient(linear,
            left top,
            left bottom,
            color-stop(80%, rgba(0, 0, 0, 0)),
            color-stop(80%, var(--scond-color)));
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 80%,
            var(--scond-color) 80%);
    font-weight: unset;
    background-size: 100px 1.05em !important;
}

.about-content p {
    margin-bottom: 25px;
    font-size: 1rem;
    color: #979797;
    text-align: justify;
    font-family: 'Open Sans', sans-serif;    line-height: 25px;    font-weight: 400;


}

.about-content .signature {
    margin-top: 35px;
    margin-bottom: 35px;
}

.about-content .btn-primary {
    margin-right: 20px;
}

.album-about{
    margin: auto;
    width: 100%;
    
    align-items: center;
    display: flex;
}
.about-image{
    display: none;
}
@media only screen and (max-width: 909px) {
    .album-about{

     display: none;
    }
    .about-image{
        margin-top: 5rem;
        display: block;
    }
}

 

 .TabImage {
    margin-top: 15px;
    width: 50%;
    float: left;
    position: relative;
    height: 15rem;
    margin: auto;
    
}

.TabImage .img1 {  border: 10px solid #ddd;

    position: absolute;
    top: 0px;
    left: 42px;
    height: 260px;
    /* background: url(../img/about-shadow.png) no-repeat; */
    background-position: 25px 223px;
}
.TabImage .img2 {
    position: absolute;
    top: 40px;
    left: 2px;
    height: 260px;
    border: 10px solid #ddd;
    width: 30rem;
    object-fit: contain;
    /* background: url(../img/about-shadow.png) bottom center no-repeat; */
    background-position: 0px 223px;
}
.about-image {
    position: relative;
}

.about-image .about-img1 {
    position: relative;
    right: -100px;
    top: -80px;
    border-radius: 40px;
}

.about-image .about-img2 {
    position: absolute;
    left: 20px;
    bottom: -80px;
}

.about-image .shape-img {
    position: absolute;
    top: -28px;
    left: 48%;
    -webkit-animation: movebounce 5s linear infinite;
    animation: movebounce 5s linear infinite;
}

.about-image .btn {
    position: absolute;
    bottom: -20px;
    right: 0;
}

/*about-style-two*/
.about-area-two {
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.about-area-two .bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about-area-two .about-image .about-img1 {
    position: relative;
    right: 0;
    top: 0;
}

.about-area-two .about-image .btn {
    bottom: 0;
}

.about-area-two .about-content .signature {
    margin-bottom: 0;
}

/*about-style-three*/
.about-area-three {
    position: relative;
    overflow: hidden;
    z-index: 1;
}

.about-area-three.bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.about-area-three .about-image .about-img1 {
    position: relative;
    right: 0;
    top: 0;
}

.about-area-three .about-image .btn {
    bottom: 0;
    left: 70px;
    right: auto;
}

.about-area-three .about-content .signature {
    margin-bottom: 0;
}

.about-area-three .about-content h6 {
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 20px;
}

.about-area-three .about-content p {
    margin-bottom: 0;
}

.about-area-three .about-content ul {
    padding: 0;
    margin: 20px 0 0;
    list-style-type: none;
}

.about-area-three .about-content ul li {
    margin-bottom: 10px;
    color: #707070;
    padding-left: 20px;
    position: relative;
}

.about-area-three .about-content ul li i {
    color: var(--scond-color);
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -7px;
}

.about-area-three .about-content ul li:last-child {
    margin-bottom: 0;
}

.scroll-animation {
    opacity: 0;
    transform: translateX(30px);
    transition: opacity 0.5s, transform 0.7s;
}

.scroll-animation.visible {
    animation: fadeInRight 1s both;
}

.scroll-animation.visibleText {
    animation: fadeInLeft 1s both;
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;

        transform: translateX(0);
    }
}

/*================================================
Why Choose Us CSS
=================================================*/

/*================================================
Speakers CSS
=================================================*/
/* .single-speakers {
    overflow: hidden;
    position: relative;
}

.single-speakers::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    background: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(40%, transparent), color-stop(58%, rgba(0, 0, 0, 0.3)), color-stop(89%, rgba(0, 0, 0, 0.8)), to(rgba(0, 0, 0, 0.63)));
    background: linear-gradient(transparent 0%, transparent 40%, rgba(0, 0, 0, 0.3) 58%, rgba(0, 0, 0, 0.8) 89%, rgba(0, 0, 0, 0.63) 100%);
    width: 100%;
    height: 100%;
}

.single-speakers .speakers-content {
    position: absolute;
    bottom: 35px;
    left: 35px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 100%;
    
}

.speakers-content>h3 {
    text-overflow: ellipsis;
    margin-bottom: 0;
}

.single-speakers .speakers-content h3 a {
    color: var(--scond-color);
}

.single-speakers .speakers-content h3 a:hover,
.single-speakers .speakers-content h3 a:focus {
    color: var(--green-color);
}

.single-speakers .speakers-content span {
    display: block;
    color: #ffffff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-speakers ul {
    padding: 15px;
    margin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    list-style-type: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    right: -15px;
    top: 0;
    background-color: #ffffff;
}

.single-speakers ul li {
    display: block;
    margin-bottom: 10px;
}

.single-speakers ul li:last-child {
    margin-bottom: 0;
}

.single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: var(--scond-color);
    color: #ffffff;
    border-radius: 50%;
    font-size: 14px;
}

.single-speakers ul li a.facebook {
    background-color: #4267b2;
}

.single-speakers ul li a.twitter {
    background-color: #38A1F3;
}

.single-speakers ul li a.instagram {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.single-speakers ul li a.linkedin {
    background-color: #0077B5;
}

.single-speakers ul li a:hover,
.single-speakers ul li a:focus {
    background-color: var(--scond-color);
    color: #ffffff;
}

.single-speakers:hover .speakers-content,
.single-speakers:focus .speakers-content {
    bottom: 45px;
}

.single-speakers:hover ul,
.single-speakers:focus ul {
    right: 0;
    opacity: 1;
    visibility: visible;
}

/*Speakers-Style-Two*/
/* .elkevent-single-speakers {
    overflow: hidden;
    position: relative;
}

.elkevent-single-speakers .speakers-content {
    position: absolute;
    bottom: -25px;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    height: auto;
    padding: 25px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.elkevent-single-speakers .speakers-content h3 {
    font-size: 24px;
    margin-bottom: 0;
}

.elkevent-single-speakers .speakers-content h3 a {
    color: var(--main-color);
}

.elkevent-single-speakers .speakers-content h3 a:hover,
.elkevent-single-speakers .speakers-content h3 a:focus {
    color: var(--scond-color);
}

.elkevent-single-speakers .speakers-content span {
    display: block;
    margin-top: 8px;
    color: var(--scond-color);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.elkevent-single-speakers ul {
    padding: 15px 20px 10px;
    margin: 0 0 0;
    opacity: 0;
    visibility: hidden;
    list-style-type: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: absolute;
    right: -15px;
    top: 0;
    background-color: #ffffff;
}

.elkevent-single-speakers ul li {
    display: block;
    margin-bottom: 5px;
}

.elkevent-single-speakers ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    background-color: var(--scond-color);
    color: #ffffff;
    border-radius: 50%;
    font-size: 14px;
}

.elkevent-single-speakers ul li a.facebook {
    background-color: #4267b2;
}

.elkevent-single-speakers ul li a.twitter {
    background-color: #38A1F3;
}

.elkevent-single-speakers ul li a.instagram {
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
    background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

.elkevent-single-speakers ul li a.linkedin {
    background-color: #0077B5;
}

.elkevent-single-speakers ul li a:hover,
.elkevent-single-speakers ul li a:focus {
    background-color: var(--scond-color);
    color: #ffffff;
}

.elkevent-single-speakers img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.elkevent-single-speakers:hover .speakers-content,
.elkevent-single-speakers:focus .speakers-content {
    bottom: 0;
    opacity: 1;
    visibility: visible;
}

.elkevent-single-speakers:hover ul,
.elkevent-single-speakers:focus ul {
    right: 0;
    opacity: 1;
    visibility: visible;
}

.elkevent-single-speakers>img {
    width: 100%;
    padding-left: 1%;
    padding-right: 1%;
}


.elkevent-single-speakers:hover img,
.elkevent-single-speakers:focus img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
} */

/*Speakers-style-three*/
.speakers-area {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.single-speakers-box {
    background: #ffffff;
    height: 170px;
    width: 350px;
    margin-bottom: 1rem;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
}

.single-speakers-box .speakers-content {
    background: white;
    padding: 30px 0;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-overflow: ellipsis;
    width: 350px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-top: 12px;
    border-top: 1px solid rgba(0, 0, 0, 0.09);
    -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
}

.single-speakers-box .speakers-content::before {
    width: 0;
    height: 20px;
    color: black;
    background: var(--scond-color);
    position: absolute;
    top: -20px;
    right: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.09);
}

.single-speakers-box .speakers-content h1 {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.single-speakers-box .speakers-content h3 {
    color: black;
    margin: 10px;
}

.single-speakers-box .speakers-content span {
    display: block;
    text-transform: capitalize;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    color: var(--main-color);
    margin: 10px;
    margin-bottom: 20px;
}

.single-speakers-box .speakers-content .social {
    padding: 0;
    margin: 0;
    list-style-type: none;
    position: absolute;
    top: -37px;
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.socialicon {
    margin: 0.4rem;

    color: white;
}

.social {
    align-items: start;
}

.single-speakers-box .speakers-content .social li a {
    display: block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    color: #a0a0a0;
    margin-right: 10px;
    position: relative;
    z-index: 1;
}

.single-speakers-box .speakers-content .social li a:hover {
    color: #ffffff;
}

.single-speakers-box .speakers-content .social li a:hover::before {
    background: var(--therd-color);
    color: #ffffff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.single-speakers-box .speakers-content .social li a::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    z-index: -1;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-speakers-box:hover {
    -webkit-box-shadow: 0 0 28px 0 rgba(6, 74, 255, 0.22);
    box-shadow: 0 0 28px 0 rgba(6, 74, 255, 0.22);
}

.single-speakers-box:hover::before,
.single-speakers-box:hover::after {
    border-color: var(--scond-color);
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-speakers-box:hover .speakers-content {
    background: var(--scond-color);
}

.single-speakers-box:hover .speakers-content::before {
    width: 100%;
}

.single-speakers-box:hover .speakers-content .social {
    -webkit-transform: scale(1);
    transform: scale(1);
}

.single-speakers-box:hover .speakers-content h3,
.single-speakers-box:hover .speakers-content span {
    color: var(--main-color);
    opacity: 70%;
}

.speakers-image {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.speakers-image img {
    height: 170px;
    padding: 5px;
    object-fit: contain;
}

/*================================================
Schedule CSS
=================================================*/
.schedule-area {
    position: relative;
    z-index: 1;
}

.schedule-area .bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.tab {
    width: 100%;
    -webkit-box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
    box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
    background-color: #ffffff;
}

.tabs {
    list-style-type: none;
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    text-align: center;
    padding: 0;
}

.tabs li {
    display: block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    background-color: var(--yellow-color);
    -webkit-transition: 0.5s;
    transition: 0.5s;
    position: relative;
}

.tabs li::before {
    width: 30px;
    height: 30px;
    left: 0;
    bottom: -15px;
    position: absolute;
    background: var(--scond-color);
    content: "";
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.tabs li a {
    font-size: 24px;
    color: #ffffff;
    text-transform: uppercase;
    padding: 25px 10px;
    font-weight: 600;
}

.tabs li a span {
    display: block;
    font-family: "Tajawal", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 5px;
}

.tabs li:nth-child(2) {
    background-color: var(--therd-color);
}

.tabs li:nth-child(3) {
    background-color: var(--green-color);
}

.tabs li:nth-child(4) {
    background-color: var(--yellow-color);
}

.tabs li.current {
    background-color: var(--scond-color);
}

.tabs li.current::before {
    opacity: 1;
    visibility: visible;
}

.tab_content {
    padding: 30px;
    background-color: #f9f9ff;
}

.tab_content .tabs_item {
    display: none;
}

.tab_content .tabs_item:first-child {
    display: block;
}

.tab_content .tabs_item .accordion {
    list-style-type: none;
    padding: 0;
    margin: 0;
    border: none;
}

.tab_content .tabs_item .accordion .accordion-item {
    display: block;
    margin-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 35px;
    padding-top: 35px;
    background-color: #ffffff;
}

.tab_content .tabs_item .accordion .accordion-item:last-child {
    margin-bottom: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title {
    display: flex;

    /* position: relative; */
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author {
    max-width: 100px;
    margin-right: 5%;
    /* position: absolute;
  right: 0;
  top: 50%; */
    /* -webkit-transform: translateY(-50%);
          transform: translateY(-50%); */
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author-vd {
    max-width: 60%;

    margin-right: 1%;
    position: relative;
    /* position: absolute;
  right: 0;
  top: 50%; */
    /* -webkit-transform: translateY(-50%);
          transform: translateY(-50%); */
}

.icon-play-vd {
    display: none;
}

.author-vd img:hover+.icon-play-vd {
    color: white;
    width: 2rem;
    position: absolute;
    display: block;
    top: 35%;
    left: 40%;
}

.author-vd img:hover {
    opacity: 50%;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .author-multi {
    overflow: hidden;
}

/* .tab_content .tabs_item .accordion .accordion-item .accordion-title .author-multi img {
  width: 47%;
  border-radius: 50%;
  margin-bottom: 0.2rem;
  margin-right: 1.5%;
  margin-left: 1.5%;
  margin-top: 0.2rem;
  float: left;
} */

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info h3 {
    font-size: 20px;
    margin-bottom: 15px;
    text-transform: capitalize;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
    display: inline-block;
    color: #707070;
    margin-right: 20px;
    font-size: 14px;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li:last-child {
    margin-right: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li i {
    margin-right: 5px;
    color: var(--scond-color);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li span {
    color: var(--therd-color);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content {
    margin-top: 25px;
    display: none;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content p {
    font-size: 16px;
    margin-bottom: 20px;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content .location b {
    color: var(--scond-color);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-content .location span {
    color: var(--therd-color);
}

.btn-box {
    text-align: center;
    margin-top: 50px;
}

.btn-box .btn {
    margin: 0 15px;
}

/*schedule-style-two*/
.schedule-slides-item .single-schedule {
    position: relative;
    background: #ffffff;
    text-align: center;
    padding: 0 0 0 200px;
}

.schedule-slides-item .single-schedule .schedule-date {
    font-size: 24px;
    color: #ffffff;
    background: var(--scond-color);
    text-transform: uppercase;
    padding: 25px 40px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 200px;
}

.schedule-slides-item .single-schedule .schedule-date span {
    display: block;
    font-family: "Tajawal", sans-serif;
    font-size: 15px;
    text-transform: capitalize;
    margin-top: 5px;
}

.schedule-slides-item .single-schedule .schedule-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    padding: 20px;
    text-align: left;
}

.schedule-slides-item .single-schedule .schedule-content .author {
    max-width: 100px;
    margin-right: 25px;
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(1) {
    -webkit-clip-path: polygon(50% 0%,
            38.11% 6.7%,
            24.99% 6.72%,
            18.06% 18.44%,
            6.7% 25%,
            6.56% 38.64%,
            0% 50%,
            6.7% 61.89%,
            6.7% 75%,
            18.44% 81.94%,
            25% 93.3%,
            38.64% 93.44%,
            50% 100%,
            61.88% 93.3%,
            75% 93.3%,
            81.94% 81.56%,
            93.3% 74.9%,
            93.44% 61.36%,
            100% 50%,
            93.3% 38.11%,
            93.3% 25%,
            81.56% 18.06%,
            74.96% 6.7%,
            61.36% 6.56%,
            50% 0%);
    clip-path: polygon(50% 0%,
            38.11% 6.7%,
            24.99% 6.72%,
            18.06% 18.44%,
            6.7% 25%,
            6.56% 38.64%,
            0% 50%,
            6.7% 61.89%,
            6.7% 75%,
            18.44% 81.94%,
            25% 93.3%,
            38.64% 93.44%,
            50% 100%,
            61.88% 93.3%,
            75% 93.3%,
            81.94% 81.56%,
            93.3% 74.9%,
            93.44% 61.36%,
            100% 50%,
            93.3% 38.11%,
            93.3% 25%,
            81.56% 18.06%,
            74.96% 6.7%,
            61.36% 6.56%,
            50% 0%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(2) {
    -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(3) {
    -webkit-clip-path: polygon(50% 0%,
            100% 25%,
            100% 75%,
            50% 100%,
            0% 75%,
            0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(4) {
    border-radius: 50%;
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(5) {
    -webkit-clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
    clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(6) {
    -webkit-clip-path: polygon(30% 0%,
            70% 0%,
            100% 30%,
            100% 70%,
            70% 100%,
            30% 100%,
            0% 70%,
            0% 30%);
    clip-path: polygon(30% 0%,
            70% 0%,
            100% 30%,
            100% 70%,
            70% 100%,
            30% 100%,
            0% 70%,
            0% 30%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(7) {
    -webkit-clip-path: polygon(50% 0%,
            83% 12%,
            100% 43%,
            94% 78%,
            68% 100%,
            32% 100%,
            6% 78%,
            0% 43%,
            17% 12%);
    clip-path: polygon(50% 0%,
            83% 12%,
            100% 43%,
            94% 78%,
            68% 100%,
            32% 100%,
            6% 78%,
            0% 43%,
            17% 12%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(8) {
    -webkit-clip-path: polygon(50% 0%,
            80% 10%,
            100% 35%,
            100% 70%,
            80% 90%,
            50% 100%,
            20% 90%,
            0% 70%,
            0% 35%,
            20% 10%);
    clip-path: polygon(50% 0%,
            80% 10%,
            100% 35%,
            100% 70%,
            80% 90%,
            50% 100%,
            20% 90%,
            0% 70%,
            0% 35%,
            20% 10%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(9) {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
}

.schedule-slides-item .single-schedule .schedule-content .author img:nth-child(10) {
    -webkit-clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
    clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
}

.schedule-slides-item .single-schedule .schedule-content .author-multi {
    overflow: hidden;
}

.schedule-slides-item .single-schedule .schedule-content .author-multi img {
    width: 47%;
    border-radius: 50%;
    margin-bottom: 0.4rem;
    margin-right: 3%;
    float: left;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 12px;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a {
    color: var(--main-color);
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:hover,
.schedule-slides-item .single-schedule .schedule-content .schedule-info h3 a:focus {
    color: var(--scond-color);
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li {
    display: inline-block;
    color: #707070;
    margin-right: 20px;
    font-size: 14px;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li i {
    margin-right: 6px;
    color: var(--scond-color);
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a {
    display: inline-block;
    color: var(--therd-color);
}

.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:hover,
.schedule-slides-item .single-schedule .schedule-content .schedule-info ul li a:focus {
    color: var(--scond-color);
}

.schedule-slides-item .single-schedule:nth-child(2) .schedule-date {
    background: var(--therd-color);
}

.schedule-slides-item .single-schedule:nth-child(3) .schedule-date {
    background: #b5daa2;
}

.schedule-slides-item .single-schedule:nth-child(4) .schedule-date {
    background: #2e93d1;
}

.schedule-slides-item .single-schedule:nth-child(5) .schedule-date {
    background: var(--therd-color);
}

.schedule-slides-item .single-schedule:nth-child(6) .schedule-date {
    background: #9dd29f;
}

.schedule-slides-item .single-schedule:nth-child(7) .schedule-date {
    background: #2e93d1;
}

.schedule-slides.owl-theme .owl-nav {
    margin-top: 0;
    line-height: 0.01;
}

.schedule-slides.owl-theme .owl-nav [class*="owl-"] {
    color: var(--main-color);
    font-size: 25px;
    margin: 0;
    background: transparent;
    border-radius: 0;
    position: absolute;
    left: -25px;
    top: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: 0;
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.schedule-slides.owl-theme .owl-nav [class*="owl-"]:hover,
.schedule-slides.owl-theme .owl-nav [class*="owl-"]:focus {
    color: var(--scond-color);
}

.schedule-slides.owl-theme .owl-nav [class*="owl-"].owl-next {
    left: auto;
    right: -25px;
}

/*schedule-style-three*/
.schedule-style-three {
    padding-bottom: 90px;
}

.schedule-style-three .col-lg-6:nth-child(2) .single-schedule-item .schedule-date {
    background-color: var(--therd-color);
}

.schedule-style-three .col-lg-6:nth-child(3) .single-schedule-item .schedule-date {
    background-color: #2e93d1;
}

.schedule-style-three .col-lg-6:nth-child(4) .single-schedule-item .schedule-date {
    background-color: #9dd29f;
}

.schedule-style-three .col-lg-6:nth-child(5) .single-schedule-item .schedule-date {
    background-color: var(--therd-color);
}

.schedule-style-three .col-lg-6:nth-child(6) .single-schedule-item .schedule-date {
    background-color: #2baae2;
}

.schedule-style-three .col-lg-6:nth-child(7) .single-schedule-item .schedule-date {
    background-color: #9dd29f;
}

.single-schedule-item {
    text-align: center;
    margin-bottom: 30px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

.single-schedule-item .schedule-date {
    font-size: 24px;
    color: #ffffff;
    background: var(--scond-color);
    text-transform: uppercase;
    padding: 20px;
}

.single-schedule-item .schedule-date span {
    display: block;
    font-family: "Tajawal", sans-serif;
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 5px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
    border-bottom: 1px solid #eeeeee;
    padding: 25px 20px;
    text-align: left;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author {
    max-width: 90px;
    margin-right: 20px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(1) {
    -webkit-clip-path: polygon(50% 0%,
            38.11% 6.7%,
            24.99% 6.72%,
            18.06% 18.44%,
            6.7% 25%,
            6.56% 38.64%,
            0% 50%,
            6.7% 61.89%,
            6.7% 75%,
            18.44% 81.94%,
            25% 93.3%,
            38.64% 93.44%,
            50% 100%,
            61.88% 93.3%,
            75% 93.3%,
            81.94% 81.56%,
            93.3% 74.9%,
            93.44% 61.36%,
            100% 50%,
            93.3% 38.11%,
            93.3% 25%,
            81.56% 18.06%,
            74.96% 6.7%,
            61.36% 6.56%,
            50% 0%);
    clip-path: polygon(50% 0%,
            38.11% 6.7%,
            24.99% 6.72%,
            18.06% 18.44%,
            6.7% 25%,
            6.56% 38.64%,
            0% 50%,
            6.7% 61.89%,
            6.7% 75%,
            18.44% 81.94%,
            25% 93.3%,
            38.64% 93.44%,
            50% 100%,
            61.88% 93.3%,
            75% 93.3%,
            81.94% 81.56%,
            93.3% 74.9%,
            93.44% 61.36%,
            100% 50%,
            93.3% 38.11%,
            93.3% 25%,
            81.56% 18.06%,
            74.96% 6.7%,
            61.36% 6.56%,
            50% 0%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(2) {
    -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
    clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(3) {
    -webkit-clip-path: polygon(50% 0%,
            100% 25%,
            100% 75%,
            50% 100%,
            0% 75%,
            0% 25%);
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(4) {
    border-radius: 50%;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(5) {
    -webkit-clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
    clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(6) {
    -webkit-clip-path: polygon(30% 0%,
            70% 0%,
            100% 30%,
            100% 70%,
            70% 100%,
            30% 100%,
            0% 70%,
            0% 30%);
    clip-path: polygon(30% 0%,
            70% 0%,
            100% 30%,
            100% 70%,
            70% 100%,
            30% 100%,
            0% 70%,
            0% 30%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(7) {
    -webkit-clip-path: polygon(50% 0%,
            83% 12%,
            100% 43%,
            94% 78%,
            68% 100%,
            32% 100%,
            6% 78%,
            0% 43%,
            17% 12%);
    clip-path: polygon(50% 0%,
            83% 12%,
            100% 43%,
            94% 78%,
            68% 100%,
            32% 100%,
            6% 78%,
            0% 43%,
            17% 12%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(8) {
    -webkit-clip-path: polygon(50% 0%,
            80% 10%,
            100% 35%,
            100% 70%,
            80% 90%,
            50% 100%,
            20% 90%,
            0% 70%,
            0% 35%,
            20% 10%);
    clip-path: polygon(50% 0%,
            80% 10%,
            100% 35%,
            100% 70%,
            80% 90%,
            50% 100%,
            20% 90%,
            0% 70%,
            0% 35%,
            20% 10%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(9) {
    -webkit-clip-path: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author img:nth-child(10) {
    -webkit-clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
    clip-path: polygon(50% 0%,
            90% 20%,
            100% 60%,
            75% 100%,
            25% 100%,
            0% 60%,
            10% 20%);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author-multi {
    overflow: hidden;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .author-multi img {
    width: 47%;
    border-radius: 50%;
    margin-bottom: 0.4rem;
    margin-right: 3%;
    float: left;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 {
    font-size: 18px;
    margin-bottom: 12px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 a {
    color: var(--main-color);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 a:hover,
.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info h3 a:focus {
    color: var(--scond-color);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li {
    display: inline-block;
    color: #707070;
    margin-right: 20px;
    line-height: 1.9;
    font-size: 14px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li i {
    margin-right: 5px;
    color: var(--scond-color);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li .icofont-user-suited {
    margin-right: 8px;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li:last-child {
    margin-right: 0;
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li a {
    display: inline-block;
    color: var(--therd-color);
}

.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li a:hover,
.single-schedule-item .schedule-item-wrapper .schedule-content .schedule-info ul li a:focus {
    color: var(--scond-color);
}

.single-schedule-item .schedule-item-wrapper .schedule-content:last-child {
    border-bottom: none;
}

/*schedule-style-four*/
.schedule-style-four .tab {
    background-color: transparent;
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

.schedule-style-four .tabs {
    display: block;
}

.schedule-style-four .tabs li::before {
    left: -15px;
    bottom: 37%;
    margin: 0 0 0;
    right: auto;
}

.schedule-style-four .tab_content {
    -webkit-box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
    box-shadow: 0 0 10px 0 rgba(137, 173, 255, 0.35);
}

/*================================================
FunFacts CSS
=================================================*/
.funfacts-area {
    position: relative;
    z-index: 1;
    /* background-image: url("../images/funfacts-bg.jpg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.funfacts-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--main-color);
    opacity: 0.88;
}

.single-funfact {
    text-align: center;
}

.single-funfact .icon {
    color: #ffffff;
    font-size: 40px;
}

.single-funfact h3 {
    color: #ffffff;
    font-size: 55px;
    margin-top: 10px;
    margin-bottom: 8px;
}

.single-funfact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
}

/*funfact-style-two*/
.funFact {
    position: relative;
    padding-left: 60px;
}

.funFact .icon {
    color: #ffffff;
    font-size: 40px;
    position: absolute;
    left: 0;
    top: 0;
}

.funFact h3 {
    color: #ffffff;
    font-size: 55px;
    margin-top: 0;
    margin-bottom: 8px;
}

.funFact p {
    color: #ffffff;
    letter-spacing: 1px;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 1;
}

.col-lg-3:nth-child(1) .single-funfact h3,
.col-lg-3:nth-child(1) .funFact h3 {
    color: var(--scond-color);
}

.col-lg-3:nth-child(2) .single-funfact h3,
.col-lg-3:nth-child(2) .funFact h3 {
    color: var(--therd-color);
}

.col-lg-3:nth-child(3) .single-funfact h3,
.col-lg-3:nth-child(3) .funFact h3 {
    color: var(--yellow-color);
}

.col-lg-3:nth-child(4) .single-funfact h3,
.col-lg-3:nth-child(4) .funFact h3 {
    color: var(--green-color);
}

/*================================================
Pricing CSS
=================================================*/
.pricing-area {
    position: relative;
    z-index: 1;
}

.pricing-area.bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.sociallink :hover {
    color: black;
}

.sociallinks {
    display: flex;
    justify-content: space-around;
}

.pricing-table-box {
    padding-bottom: 30px;
    background: #ffffff;
    text-align: center;
    z-index: 1;
    position: relative;
    border-radius: 5px;
    /* background-image: url("../images/patt.png"); */
    background-position: center center;
    overflow: hidden;
    -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.pricing-table-box .pricingTable-header {
    padding: 30px 15px 10px;
    background: var(--scond-color);
    -webkit-clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
    clip-path: polygon(50% 100%, 100% 60%, 100% 0, 0 0, 0 60%);
    position: relative;
}

.pricing-table-box .pricingTable-header::before {
    content: "";
    width: 400px;
    height: 400px;
    border-radius: 50%;
    position: absolute;
    right: -50%;
    top: -130%;
    background: repeating-radial-gradient(rgba(255, 255, 255, 0.04),
            rgba(255, 255, 255, 0.17) 20%);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.pricing-table-box .title {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.pricing-table-box .price-value {
    display: block;
    font-size: 55px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 30px;
}

.pricing-table-box .price-value sup {
    font-size: 23px;
    top: -25px;
}

.pricing-table-box .pricing-content {
    padding: 35px 0;
    margin-bottom: 0;
    list-style-type: none;
}

.pricing-table-box .pricing-content li {
    color: #707070;
    text-transform: capitalize;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.pricing-table-box .pricing-content li:last-child {
    margin-bottom: 0;
}

.pricing-table-box .pricing-content li:first-child {
    border-top: 1px solid #eeeeee;
    padding-top: 12px;
}

.pricing-table-box:hover,
.pricing-table-box:focus,
.pricing-table-box.active {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
}

.pricing-table-box:hover .pricingTable-header::before,
.pricing-table-box:focus .pricingTable-header::before,
.pricing-table-box.active .pricingTable-header::before {
    right: 50%;
}

/*pricing-style-two*/
.pricing-plan {
    position: relative;
    -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    text-align: center;
    padding-bottom: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: #ffffff;
}

.pricing-plan h3 {
    font-size: 24px;
    padding: 30px 15px;
    margin-bottom: 100px;
    background-color: var(--scond-color);
    color: #ffffff;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.pricing-plan h3 span {
    display: block;
    color: var(--scond-color);
    background: #ffffff;
    height: 150px;
    width: 150px;
    line-height: 155px;
    font-size: 35px;
    -webkit-box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    box-shadow: 7px 5px 30px 0 rgba(72, 73, 121, 0.15);
    margin: 30px auto -95px;
    border-radius: 50%;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.pricing-plan h3 span sup {
    font-weight: 500;
}

.pricing-plan .pricing-content {
    padding: 0 0 35px;
    margin-bottom: 0;
    list-style-type: none;
}

.pricing-plan .pricing-content li {
    color: #707070;
    text-transform: capitalize;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.pricing-plan .pricing-content li:last-child {
    margin-bottom: 0;
}

.pricing-plan .pricing-content li:first-child {
    border-top: 1px solid #eeeeee;
    padding-top: 12px;
}

.pricing-plan:hover,
.pricing-plan:focus {
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
}

.col-lg-4:nth-child(2) .pricing-table-box .pricingTable-header {
    background: var(--therd-color);
}

.col-lg-4:nth-child(2) .pricing-table-box .btn-primary::before,
.col-lg-4:nth-child(2) .pricing-table-box .btn-primary::after {
    background: var(--therd-color);
}

.col-lg-4:nth-child(2) .pricing-plan h3 {
    background: var(--therd-color);
}

.col-lg-4:nth-child(2) .pricing-plan h3 span {
    color: var(--therd-color);
}

.col-lg-4:nth-child(2) .pricing-plan .btn-primary::before,
.col-lg-4:nth-child(2) .pricing-plan .btn-primary::after {
    background: var(--therd-color);
}

.col-lg-4:nth-child(3) .pricing-table-box .pricingTable-header {
    background: var(--green-color);
}

.col-lg-4:nth-child(3) .pricing-table-box .btn-primary::before,
.col-lg-4:nth-child(3) .pricing-table-box .btn-primary::after {
    background: var(--green-color);
}

.col-lg-4:nth-child(3) .pricing-plan h3 {
    background: var(--green-color);
}

.col-lg-4:nth-child(3) .pricing-plan h3 span {
    color: var(--green-color);
}

.col-lg-4:nth-child(3) .pricing-plan .btn-primary::before,
.col-lg-4:nth-child(3) .pricing-plan .btn-primary::after {
    background: var(--green-color);
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
    overflow: hidden;
}

.partner-area .partner-title {
    text-align: center;
    margin-bottom: 50px;
}

.partner-area .partner-title h3 {
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 0;
    color: var(--scond-color);
    text-transform: uppercase;
    -webkit-transform: translateX(0) !important;
    transform: translateX(0) !important;
}

.partner-area .partner-title.gold-sponsor h3 {
    color: var(--green-color);
}

.partner-area .partner-item {
    text-align: center;
}

.partner-area .partner-item a {
    position: relative;
}

.partner-area .partner-item a img {
    width: unset !important;
    display: inline-block !important;
}

.partner-area .partner-item a img:last-child {
    position: absolute;
    left: 0;
    top: 30px;
    right: 0;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.partner-area .partner-item:hover a img:last-child {
    opacity: 1;
    visibility: visible;
    top: 0;
}

.partner-area .border {
    border: 0.3px solid #eeeeee !important;
    margin-top: 40px;
    margin-bottom: 40px;
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;
}

.blog-area.bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.single-blog-post {
    position: relative;

    margin-bottom: 30px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.06);
    height: 30rem;
    /* Hauteur fixe souhaitée pour chaque blog */
    overflow: hidden;
    /* Gérer le débordement du contenu si nécessaire */
}

.single-blog-post .blog-image {
    position: relative;
    overflow: hidden;
    height: 15rem;
}


.single-blog-post h3 {
    color: #000000;
}
.single-blog-post .blog-image a {
    display: block;
}

.single-blog-post .blog-image a img {
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-blog-post .blog-image .post-tag {
    position: absolute;
    right: 0;
    top: 60px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-blog-post .blog-image .post-tag a {
    background: var(--scond-color);
    color: #ffffff;
    padding: 9px 28px;
}

.single-blog-post .blog-post-content {

    padding: 25px;
    height: 25rem;
}

 .date{
    color: gray;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-blog-post .blog-post-content h3 {
    margin-top: 13px;
    margin-bottom: 15px;
    line-height: 30px;
    font-size: 15px;

}

.single-blog-post .blog-post-content h3 a {
    color: #333333;
}

.single-blog-post .blog-post-content h3 a:hover,
.single-blog-post .blog-post-content h3 a:focus {
    color: var(--scond-color);
}

.single-blog-post .blog-post-content p {
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 15px;

}
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #006633;
    border-color: #006633;
}
.page-link {
    position: relative;
    display: block;
    color:#006633;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.pagination{
    
    align-items: center;
    margin: auto;
}
.paginate{
 
    display: flex;
    align-items: center;
    width: 50%;
    margin: auto;
}
.read-more-btn-container {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: right;
    padding-bottom: 20px;
}

.single-blog-post .read-more-btn {

    font-weight: 500;
    color: #006633;
}

.single-blog-post .read-more-btn:hover {
    color: #006633;
    letter-spacing: 1px;
}

.single-blog-post:hover .blog-image a img,
.single-blog-post:focus .blog-image a img {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}

.single-blog-post:hover .blog-image .post-tag,
.single-blog-post:focus .blog-image .post-tag {
    top: 70px;
}

.single-blog-post:hover .blog-image .post-tag a,
.single-blog-post:focus .blog-image .post-tag a {
    background: var(--green-color);
    color: #ffffff;
}

.blog-slides.owl-theme .owl-nav.disabled+.owl-dots {
    margin-top: 25px;
    line-height: .01;
}

/*blog-style-two*/
.blog-section {
    padding-bottom: 90px;
}

.single-blog-card {
    position: relative;
    margin-bottom: 30px;
}

.single-blog-card>a {
    display: block;
}

.single-blog-card>a img {
    width: 100%;
}

.single-blog-card::before {
    opacity: .60;
    background: var(--main-color);
    content: "";
    height: 100%;
    bottom: 0;
    left: 0;
    z-index: 1;
    right: 0;
    position: absolute;
    width: 100%;
}

.single-blog-card .post-tag {
    position: absolute;
    right: 0;
    top: 30px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 2;
}

.single-blog-card .post-tag a {
    background: var(--scond-color);
    color: #ffffff;
    padding: 9px 28px;
}

.single-blog-card .blog-post-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 20px 30px 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    z-index: 2;
}

.single-blog-card .blog-post-content h3 {
    font-size: 22px;
    margin-bottom: 15px;
    line-height: 1.5;
}

.single-blog-card .blog-post-content h3 a {
    color: #ffffff;
}

.single-blog-card .blog-post-content h3 a:hover,
.single-blog-card .blog-post-content h3 a:focus {
    color: var(--scond-color);
}

.single-blog-card .blog-post-content p {
    color: #ffffff;
}

.single-blog-card .blog-post-content .read-more-btn {
    position: relative;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    color: #006633;
}

.single-blog-card .blog-post-content .read-more-btn:hover {
    color:#006633;
    letter-spacing: 1px;
}

.single-blog-card:hover .post-tag,
.single-blog-card:focus .post-tag {
    top: 40px;
}

.single-blog-card:hover .post-tag a,
.single-blog-card:focus .post-tag a {
    background: var(--green-color);
    color: #ffffff;
}

.single-blog-card:hover .blog-post-content,
.single-blog-card:focus .blog-post-content {
    padding-bottom: 30px;
}

.single-blog-card:hover .blog-post-content .read-more-btn,
.single-blog-card:focus .blog-post-content .read-more-btn {
    opacity: 1;
    visibility: visible;
}

/*================================================
Buy Tickets CSS
=================================================*/
.buy-tickets-area {
    position: relative;
    z-index: 1;
    /* background-image: url("../images/buy-tickets-bg.jpg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.buy-tickets-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--main-color);
    opacity: 0.88;
}

.buy-tickets-area.ptb-120 {
    padding-bottom: 200px;
}

.buy-tickets .section-title {
    margin-bottom: 0;
}

.buy-tickets .section-title h2 {
    color: #ffffff;
}

.buy-tickets .section-title p {
    color: #ffffff;
    margin: 0;
}

.buy-tickets .buy-ticket-btn {
    text-align: right;
}

/*================================================
Subscribe CSS
=================================================*/
.subscribe-area {
    text-align: center;
    position: relative;
    margin-top: -80px;
    z-index: 2;
}

.subscribe-area::before {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 72%;
    background: var(--main-color);
    content: "";
    z-index: -1;
}

.subscribe-area .subscribe-inner {
    padding: 50px;
    -webkit-transform: unset !important;
    transform: unset !important;
    /* background-image: url("../images/color-bg.jpg"); */
    background-repeat: no-repeat;
}

.subscribe-area .subscribe-inner span {
    color: #ffffff;
    font-family: "Tajawal", sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    display: block;
}

.subscribe-area .subscribe-inner h2 {
    font-size: 40px;
    margin-top: 10px;
    margin-bottom: 0;
    color: #ffffff;
}

.subscribe-area .subscribe-inner .newsletter-form {
    max-width: 650px;
    position: relative;
    margin: 35px auto 0;
}

.subscribe-area .subscribe-inner .newsletter-form .form-control {
    border: none;
    height: 60px;
    -webkit-box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
    box-shadow: 0 8px 6px -6px rgba(50, 51, 51, 0.4);
}

.subscribe-area .subscribe-inner .newsletter-form .btn {
    position: absolute;
    right: 0;
    top: 0;
    text-transform: uppercase;
    height: 60px;
}

.subscribe-area .subscribe-inner .newsletter-form .btn:after,
.subscribe-area .subscribe-inner .newsletter-form .btn::before {
    background: var(--therd-color);
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 180px;
}

.about {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 180px;
    background: url("../images/bg7.PNG");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.init {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 180px;
    background: url("../images/bg1.PNG");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.actu {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 180px;
    background: url("../images/bg5.PNG");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.plus {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 180px;
    background: url("../images/bg4.PNG");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.contact {
    position: relative;
    z-index: 1;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    padding-top: 250px;
    padding-bottom: 180px;
    background: url("../images/bg3.PNG");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.page-title-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    opacity: 0.88;
}

.page-title-area h1 {
    color: #ffffff;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 40px;
}

.page-title-area span {
    display: block;
    color: #ffffff;
    margin-top: 10px;
    font-size: 17px;
}

.page-title-area ul {
    padding: 0;
    margin: 12px 0 0;
    list-style-type: none;
}

.page-title-area ul li {
    display: inline-block;
    position: relative;
    color: var(--scond-color);
    font-size: 16px;
    margin: 0 13px;
}

.page-title-area ul li a {
    color: #ffffff;
}

.page-title-area ul li a:hover,
.page-title-area ul li a:focus {
    color: var(--therd-color);
}

.page-title-area ul li::before {
    content: "";
    position: absolute;
    left: -15px;
    top: 50%;
    width: 2px;
    height: 15px;
    background: var(--yellow-color);
    margin-top: -9px;
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
}

.page-title-area ul li:first-child {
    margin-left: 0;
}

.page-title-area ul li:first-child::before {
    display: none;
}

.page-title-area ul li:last-child {
    margin-right: 0;
}

/*================================================
Login CSS
=================================================*/
.login-area {
    height: 100vh;
    position: relative;
    z-index: 100000;
    /* background-image: url("../images/main-bg1.jpg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.login-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--scond-color);
    opacity: 0.77;
}

.login-area .login-form {
    max-width: 540px;
    background: #ffffff;
    margin: 0 auto;
}

.login-area .login-form h3 {
    background: var(--therd-color);
    padding: 30px 0 25px;
    font-size: 24px;
    margin-bottom: 0;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
}

.login-area .login-form form {
    padding: 35px;
}

.login-area .login-form form label {
    font-weight: 500;
}

.login-area .login-form form .form-control {
    background: transparent;
    font-size: 13px;
}

.login-area .login-form .btn {
    display: block;
    width: 100%;
    margin-top: 20px;
}

.login-area .login-form p {
    overflow: hidden;
    margin-bottom: 0;
    margin-top: 20px;
}

.login-area .login-form p a {
    color: var(--main-color);
    font-weight: 600;
}

.login-area .login-form p a:hover,
.login-area .login-form p a:focus {
    color: var(--scond-color);
}

.login-area .login-form p a.pull-left {
    float: left;
}

.login-area .login-form p a.pull-right {
    float: right;
}

/*================================================
Signup CSS
=================================================*/
.signup-area {
    height: 100vh;
    position: relative;
    z-index: 10000;
    /* background-image: url("../images/main-bg2.jpg"); */
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
}

.signup-area::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: var(--scond-color);
    opacity: 0.77;
}

.signup-area .signup-form {
    max-width: 540px;
    background: #ffffff;
    margin: 0 auto;
}

.signup-area .signup-form h3 {
    background: var(--therd-color);
    padding: 30px 0 25px;
    font-size: 24px;
    margin-bottom: 0;
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
}

.signup-area .signup-form form {
    padding: 35px;
}

.signup-area .signup-form form label {
    font-weight: 500;
}

.signup-area .signup-form form .form-control {
    background: transparent;
    font-size: 13px;
}

.signup-area .signup-form .btn {
    display: block;
    width: 100%;
    margin-top: 20px;
}

.signup-area .signup-form p {
    text-align: center;
    color: var(--main-color);
    margin-bottom: 0;
    margin-top: 20px;
}

.signup-area .signup-form p a {
    color: var(--main-color);
    display: inline-block;
    font-weight: 600;
}

.signup-area .signup-form p a:hover,
.signup-area .signup-form p a:focus {
    color: var(--scond-color);
}

/*================================================
404 Error CSS
=================================================*/
.error-area {
    background-color: #f1f1f1;
    padding: 30px 15px;
    height: 100vh;
    text-align: center;
    z-index: 10000;
    position: relative;
}

.error-area h1 {
    font-size: 150px;
    font-weight: bold;
    line-height: 110px;
    margin: 0;
    color: var(--scond-color);
}

.error-area h1 span {
    color: var(--yellow-color);
}

.error-area h1 b {
    font-weight: unset;
    color: var(--green-color);
}

.error-area h3 {
    margin: 35px 0 15px;
}

.error-area p {
    margin: 10px 0 20px;
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion {
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 60px;
    background: #ffffff;
    border-radius: 10px;
}

.faq-accordion .accordion {
    border: none;
    border-radius: 0;
}

.faq-accordion .accordion__item {
    margin-bottom: 15px;
}

.faq-accordion .accordion__item .accordion__button {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    color: var(--main-color);
    outline: 0;
    font-weight: 600;
    font-size: 18px;
}

.faq-accordion .accordion__item:last-child {
    margin-bottom: 0;
}

.faq-accordion .accordion__item+.accordion__item {
    border: none;
}

.faq-contact {
    margin-top: 45px;
    -webkit-box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    padding: 60px;
    text-align: center;
    border-radius: 4px;
}

.faq-contact h3 {
    font-size: 25px;
    margin-bottom: 35px;
}

.faq-contact .form-group {
    margin-bottom: 25px;
}

.faq-contact .form-control {
    background-color: #f5f5f5;
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon {
    position: relative;
    z-index: 1000;
    height: 100vh;
    text-align: center;
    /* background-image: url("../images/main-bg3.jpg"); */
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.coming-soon::before {
    z-index: -1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--main-color);
    opacity: 0.66;
    position: absolute;
    content: "";
}

.coming-soon .coming-soon-content {
    max-width: 650px;
    margin: 0 auto;
}

.coming-soon .coming-soon-content h1 {
    color: #ffffff;
    font-size: 45px;
    text-transform: uppercase;
    margin-bottom: 0;
    line-height: 57px;
}

.coming-soon .coming-soon-content p {
    color: #c7c3c7;
    margin-top: 20px;
    margin-bottom: 35px;
}

.coming-soon .coming-soon-content form {
    position: relative;
}

.coming-soon .coming-soon-content form .form-control {
    background: transparent;
    border: 1px solid #ffffff;
    border-radius: 50px;
    color: #ffffff;
    height: 55px;
    padding-left: 20px;
}

.coming-soon .coming-soon-content form .form-control:focus,
.coming-soon .coming-soon-content form .form-control:hover {
    border-color: var(--scond-color);
}

.coming-soon .coming-soon-content form .form-control::-webkit-input-placeholder {
    color: #c7c3c7;
}

.coming-soon .coming-soon-content form .form-control:-ms-input-placeholder {
    color: #c7c3c7;
}

.coming-soon .coming-soon-content form .form-control::-ms-input-placeholder {
    color: #c7c3c7;
}

.coming-soon .coming-soon-content form .form-control::placeholder {
    color: #c7c3c7;
}

.coming-soon .coming-soon-content form button {
    position: absolute;
    right: -1px;
    height: 100%;
    background: var(--scond-color);
    top: 0;
    color: #ffffff;
    border: none;
    width: 130px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0 50px 50px 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: 0 !important;
    border: none;
}

.coming-soon .coming-soon-content form button:hover,
.coming-soon .coming-soon-content form button:focus {
    background: var(--therd-color);
}

.coming-soon .coming-soon-content #timer {
    margin-bottom: 35px;
}

.coming-soon .coming-soon-content #timer div {
    display: inline-block;
    font-size: 45px;
    font-weight: 700;
    color: #ffffff;
    width: 135px;
    height: 135px;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    padding-top: 34px;
    line-height: 40px;
    margin: 0 10px;
}

.coming-soon .coming-soon-content #timer span {
    display: block;
    font-size: 16px;
    font-weight: 400;
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    margin-top: 30px;
}

.pagination-area ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.pagination-area ul li a {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0 5px;
    background-color: #ffffff;
    border-radius: 0 !important;
    text-align: center;
    line-height: 40px;
    color: var(--main-color);
    font-size: 17px;
    font-weight: 500;
    -webkit-box-shadow: 0 2px 10px 0 #d8dde6;
    box-shadow: 0 2px 10px 0 #d8dde6;
    border: none;
}

.pagination-area ul li a.active,
.pagination-area ul li a:hover,
.pagination-area ul li a:focus {
    background: var(--scond-color);
    color: #ffffff;
    -webkit-box-shadow: unset;
    box-shadow: unset;
}

/*================================================
Blog Details CSS
=================================================*/
.blog-details .post-image {
    margin-bottom: 35px;
}

.blog-details h3 {
    font-size: 25px;
    margin-top: 5px;
    margin-bottom: 15px;
}

.blog-details .blog-meta ul {
    padding: 0;
    margin: 15px 0;
    list-style-type: none;
}

.blog-details .blog-meta ul li {
    display: inline-block;
    position: relative;
    color: #707070;
    font-size: 14px;
    margin-left: 9px;
    margin-right: 9px;
}

.blog-details .blog-meta ul li i {
    margin-right: 4px;
    color: var(--scond-color);
}

.blog-details .blog-meta ul li::before {
    width: 2px;
    height: 14px;
    background: #707070;
    left: -10px;
    top: 50%;
    position: absolute;
    content: "";
    margin-top: -6px;
    -webkit-transform: rotate(11deg);
    transform: rotate(11deg);
}

.blog-details .blog-meta ul li a {
    display: inline-block;
    color: #707070;
}

.blog-details .blog-meta ul li a:hover,
.blog-details .blog-meta ul li a:focus {
    color: var(--therd-color);
}

.blog-details .blog-meta ul li:last-child {
    margin-right: 0;
}

.blog-details .blog-meta ul li:first-child {
    margin-left: 0;
}

.blog-details .blog-meta ul li:first-child::before {
    display: none;
}

.blog-details p {
    margin-top: 15px;
    margin-bottom: 0;
    font-size: 1.2rem;
    text-align: justify;
}

.blog-details p:last-child {
    margin-bottom: 0;
}

.blog-details blockquote,
.blog-details .blockquote {
    background: linear-gradient(to right, #fff, #f6f6f6);
    border-left: 5px solid #3498db;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px 60px;
    position: relative;
    margin: 25px 0;
}

.blog-details blockquote::before,
.blog-details .blockquote::before,
.blog-details .blockquote::after {
    font-family: IcoFont;
    position: absolute;
    font-size: 40px;
    color: #3498db;
}

.blog-details blockquote::before,
.blog-details .blockquote::after {
    content: "\efcd";
}

.blockquote::before {
    left: 15px;
    top: 20%;
    transform: translateY(-50%);
}

.blockquote::after {
    content: "";
    position: absolute;
    right: 15px;
    top: 70%;
    transform: translateY(-50%) rotate(180deg);
}

.blog-details blockquote p,
.blog-details .blockquote p {
    color: var(--text-color);
    font-style: italic;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    text-align: center;
}

.blockquote-author {
    display: block;
    font-style: italic;
    font-size: 18px;
    font-weight: 600;
    color: #3498db;
    text-align: center;
}

.post-tag-media {
    background-color: #f5f7fb;
    padding: 20px;
    margin-top: 25px;
    margin-bottom: 35px;
}

.post-tag-media ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.post-tag-media ul li {
    display: inline-block;
}

.post-tag-media ul li span {
    font-weight: 600;
    margin-right: 5px;
    display: inline-block;
}

.post-tag-media ul li a {
    color: #707070;
    margin-right: 4px;
}

.post-tag-media ul li a:hover,
.post-tag-media ul li a:focus {
    color: var(--scond-color);
}

.post-tag-media ul.social-share {
    text-align: right;
}

.post-tag-media ul.social-share li {
    padding-left: 5px;
}

.comments-area .comments-title,
.comments-area .comment-reply-title {
    font-size: 22px;
    margin-bottom: 25px;
}

.comments-area .comment-reply-title {
    margin-bottom: 8px;
}

.comments-area ol,
.comments-area ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.comments-area ol li ol li,
.comments-area ol li ul li,
.comments-area ul li ol li,
.comments-area ul li ul li {
    margin-top: 15px;
    margin-left: 35px;
    margin-bottom: 15px;
}

.comments-area ol li .comment-body,
.comments-area ul li .comment-body {
    background: #f7f7ff;
    padding: 25px 20px;
    position: relative;
}

.comments-area ol li .comment-body .comment-meta,
.comments-area ul li .comment-body .comment-meta {
    position: relative;
    padding-left: 85px;
    padding-top: 8px;
}

.comments-area ol li .comment-body .comment-meta .comment-author img,
.comments-area ul li .comment-body .comment-meta .comment-author img {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;
    max-width: 68px;
}

.comments-area ol li .comment-body .comment-meta .comment-author .fn,
.comments-area ul li .comment-body .comment-meta .comment-author .fn {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    display: block;
}

.comments-area ol li .comment-body .comment-meta .comment-author .says,
.comments-area ul li .comment-body .comment-meta .comment-author .says {
    display: none;
}

.comments-area ol li .comment-body .comment-meta .comment-metadata a,
.comments-area ul li .comment-body .comment-meta .comment-metadata a {
    display: inline-block;
    color: #707070;
    font-size: 14px;
}

.comments-area ol li .comment-body .comment-meta .comment-metadata a:hover,
.comments-area ol li .comment-body .comment-meta .comment-metadata a:focus,
.comments-area ul li .comment-body .comment-meta .comment-metadata a:hover,
.comments-area ul li .comment-body .comment-meta .comment-metadata a:focus {
    color: var(--scond-color);
}

.comments-area ol li .comment-body .comment-content,
.comments-area ul li .comment-body .comment-content {
    margin-top: 25px;
}

.comments-area ol li .comment-body .reply,
.comments-area ul li .comment-body .reply {
    position: absolute;
    right: 30px;
    top: 30px;
}

.comments-area ol li .comment-body .reply a,
.comments-area ul li .comment-body .reply a {
    display: inline-block;
    background: var(--main-color);
    padding: 5px 15px;
    color: #ffffff;
}

.comments-area ol li .comment-body .reply a:hover,
.comments-area ol li .comment-body .reply a:focus,
.comments-area ul li .comment-body .reply a:hover,
.comments-area ul li .comment-body .reply a:focus {
    background: var(--scond-color);
}

.comments-area .comment-respond {
    margin-top: 30px;
}

.comments-area .comment-respond .comment-notes {
    font-size: 14px;
    margin-bottom: 20px;
}

.comments-area .comment-respond .comment-form p {
    margin-bottom: 25px;
}

.comments-area .comment-respond .comment-form-comment input,
.comments-area .comment-respond .comment-form-comment textarea,
.comments-area .comment-respond .comment-form-author input,
.comments-area .comment-respond .comment-form-author textarea,
.comments-area .comment-respond .comment-form-email input,
.comments-area .comment-respond .comment-form-email textarea,
.comments-area .comment-respond .comment-form-url input,
.comments-area .comment-respond .comment-form-url textarea {
    display: block;
    width: 100%;
    height: 50px;
    background-color: #f5f5f5;
    outline: 0 !important;
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    border: 1px solid #eeeeee;
    padding: 10px 20px;
    border-radius: 6px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.comments-area .comment-respond .comment-form-comment input:focus,
.comments-area .comment-respond .comment-form-comment textarea:focus,
.comments-area .comment-respond .comment-form-author input:focus,
.comments-area .comment-respond .comment-form-author textarea:focus,
.comments-area .comment-respond .comment-form-email input:focus,
.comments-area .comment-respond .comment-form-email textarea:focus,
.comments-area .comment-respond .comment-form-url input:focus,
.comments-area .comment-respond .comment-form-url textarea:focus {
    border-color: var(--scond-color);
}

.comments-area .comment-respond .comment-form-comment textarea,
.comments-area .comment-respond .comment-form-author textarea,
.comments-area .comment-respond .comment-form-email textarea,
.comments-area .comment-respond .comment-form-url textarea {
    height: auto;
}

.comments-area .comment-respond .form-submit {
    margin: 0 !important;
}

.comments-area .comment-respond .form-submit input {
    display: inline-block;
    background: var(--scond-color);
    border: none;
    color: #ffffff;
    padding: 13px 25px;
    text-transform: uppercase;
    font-weight: 600;
    outline: 0 !important;
    margin-top: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.comments-area .comment-respond .form-submit input:hover,
.comments-area .comment-respond .form-submit input:focus {
    background: var(--main-color);
    -webkit-box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
    box-shadow: 0 13px 27px 0 rgba(255, 82, 2, 0.11);
}

/*================================================
Sidebar CSS
=================================================*/
.sidebar .widget {
    margin-bottom: 40px;
}

.sidebar .widget:last-child {
    margin-bottom: 0;
}

.sidebar .widget .widget-title {
    font-size: 22px;
    margin-bottom: 25px;
    position: relative;
}

.sidebar .widget .widget-title::before {
    content: "";
    position: absolute;
    right: 0;
    top: 2px;
    width: 17px;
    height: 17px;
    background: var(--scond-color);
    -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.sidebar .widget:hover .widget-title::before,
.sidebar .widget:focus .widget-title::before {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}

.sidebar .widget.widget_search {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
    padding: 15px;
}

.sidebar .widget.widget_search form {
    position: relative;
}

.sidebar .widget.widget_search form .form-control {
    background: transparent;
}

.sidebar .widget.widget_search form button {
    position: absolute;
    right: 5px;
    top: 0;
    height: 100%;
    border: none;
    outline: 0;
    -webkit-box-shadow: unset;
    box-shadow: unset;
    background: transparent;
    color: var(--scond-color);
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.sidebar .widget.widget_search form button:hover,
.sidebar .widget.widget_search form button:focus {
    color: var(--therd-color);
}

.sidebar .widget.widget_categories ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.sidebar .widget.widget_categories ul li {
    position: relative;
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
}

.sidebar .widget.widget_categories ul i,
.org {
    margin: 2%;
    width: 10px;
    height: 10px;
    color: var(--scond-color);
    margin-top: -5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.sidebar .widget.widget_categories ul li:last-child {
    border-bottom: 1px solid #eeeeee;
}

.sidebar .widget.widget_categories ul li a {
    color: #707070;
}

.sidebar .widget.widget_categories ul li a:hover {
    color: var(--scond-color);
}

.sidebar .widget.widget_categories ul li:hover::before {
    border-radius: 50%;
}

.sidebar .widget.widget_recent_entries ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.sidebar .widget.widget_recent_entries ul li {
    position: relative;
    padding-left: 100px;
    margin-bottom: 15px;
    padding-right: 20px;
}

.sidebar .widget.widget_recent_entries ul li a {
    display: block;
}

.sidebar .widget.widget_recent_entries ul li a img {
    position: absolute;
    left: 0;
    top: 3px;
    width: 85px;
    height: 65px;
}

.sidebar .widget.widget_recent_entries ul li h5 {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 2px;
    font-weight: 600;
}

.sidebar .widget.widget_recent_entries ul li h5 a {
    color: var(--main-color);
    display: inline-block;
}

.sidebar .widget.widget_recent_entries ul li h5 a:hover,
.sidebar .widget.widget_recent_entries ul li h5 a:focus {
    color: var(--scond-color);
}

.sidebar .widget.widget_recent_entries ul li:last-child {
    margin-bottom: 0;
}

.sidebar .widget.widget_tag_cloud .tagcloud a {
    font-size: 14px !important;
    border: 1px dashed #eeeeee;
    padding: 6px 20px;
    margin-top: 5px;
    color: #707070;
    display: inline-block;
    background: #f5f5f5;
    margin-right: 7px;
    border-radius: 30px;
    margin-bottom: 5px;
}

.sidebar .widget.widget_tag_cloud .tagcloud a:hover {
    background: var(--scond-color);
    color: #ffffff;
    border-color: var(--scond-color);
}

.sidebar .widget.widget_archive ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.sidebar .widget.widget_archive ul li {
    position: relative;
    border-top: 1px solid #eeeeee;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 18px;
}

.sidebar .widget.widget_archive ul li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 10px;
    height: 10px;
    background: var(--scond-color);
    margin-top: -5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.sidebar .widget.widget_archive ul li:last-child {
    border-bottom: 1px solid #eeeeee;
}

.sidebar .widget.widget_archive ul li a {
    color: #707070;
    display: inline-block;
}

.sidebar .widget.widget_archive ul li a:hover {
    color: var(--scond-color);
}

.sidebar .widget.widget_archive ul li:hover::before {
    border-radius: 50%;
}

/*================================================
Contact Us CSS
=================================================*/
.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid var(--therd-color);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;

    text-align: start;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.contact-box {
    margin-top: 15px;
    background: #ffffff;
    padding: 30px 20px 30px 90px;
    border-radius: 5px;
    position: relative;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
}

.contact-box .icon {
    position: absolute;
    left: 20px;
    top: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 47px;
    border: 1px dashed var(--scond-color);
    border-radius: 50%;
    color: var(--scond-color);
    font-size: 20px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.contact-box h4 {
    font-size: 20px;
    margin-bottom: 10px;
}

.contact-box .content p {
    margin-bottom: 0;
}

.contact-box .content p a {
    color: #707070;
}

.contact-box .content p a:hover,
.contact-box .content p a:focus {
    color: var(--scond-color);
}

.contact-box .content p:last-child {
    margin-bottom: 0;
}

.contact-box:hover,
.contact-box:focus {
    -webkit-box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    box-shadow: 0 0 30px 0 rgba(67, 67, 67, 0.15);
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
}

.contact-box:hover .icon,
.contact-box:focus .icon {
    background: var(--scond-color);
    color: #ffffff;
    fill: white;
}

.form-card-container {
    height: 600px;
    /* Définir la hauteur désirée */
    margin-bottom: 30px;
    /* Ajoutez une marge entre les cartes */
}

.map-card-container {
    height: 400px;
    /* Définir la hauteur désirée */
}

.contact-form {
    display: flex;
    margin-top: 50px;
}

.leave-your-message h3 {
    margin-bottom: 12px;
    font-size: 22px;
}

.leave-your-message .stay-connected {
    margin-top: 25px;
}

.leave-your-message .stay-connected h3 {
    margin-bottom: 20px;
}

.leave-your-message .stay-connected ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.leave-your-message .stay-connected ul li {
    margin-bottom: 10px;
}

.leave-your-message .stay-connected ul li:last-child {
    margin-bottom: 0;
}

.leave-your-message .stay-connected ul li a {
    font-weight: 500;
    display: inline-block;
    color: #707070;
}

.leave-your-message .stay-connected ul li a i {
    width: 40px;
    height: 40px;
    text-align: center;
    display: inline-block;
    line-height: 40px;
    border: 1px solid;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 15px;
}

.leave-your-message .stay-connected ul li a:hover,
.leave-your-message .stay-connected ul li a:focus {
    color: var(--scond-color);
}

#contactForm {
    background: #ffffff;
    padding: 30px;
    -webkit-box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
}

#contactForm label {
    font-weight: 500;
}

#contactForm .form-control {
    background-color: #f1f1f1;
}

#contactForm ul {
    padding: 0;
    margin: 0;
}

#contactForm ul li {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

#contactForm .text-danger,
#contactForm .text-success {
    margin-bottom: 0;
    margin-top: 20px;
}

#contactForm .h4 {
    font-size: 15px;
}

/*================================================
Footer CSS
=================================================*/
.navfooter {
    display: flex;
    justify-content: center;
}

.map>iframe {
    width: 100%;
    height: 100%;
}

.footer-area {
    background-color: var(--main-color);
    border-top: 1px solid rgb(209, 209, 209);
    /* padding-top: 120px; */
}

.single-footer-widget h3 {
    color: var(--text-color);
    font-size: 24px;
    margin-bottom: 20px;
}

.single-footer-widget span {
    color: var(--text-color);
    display: block;
    margin-bottom: 10px;
}

.single-footer-widget span i {
    margin-right: 5px;
}

.single-footer-widget p {
    margin-bottom: 0;
    color: var(--text-color);
}

.single-footer-widget .location {
    position: relative;
    padding-left: 25px;
}

.single-footer-widget .location i {
    margin-right: 5px;
    position: absolute;
    left: 0;
    top: 6px;
}

.single-footer-widget .contact-authority {
    margin-top: 8px;
    color: var(--text-color);
}

.single-footer-widget .contact-authority:hover,
.single-footer-widget .contact-authority:focus {
    color: var(--scond-color);
}

.single-footer-widget .contact-authority i {
    margin-right: 5px;
}

.single-footer-widget .social-links {
    padding: 0;
    margin: 20px 0 0;
    list-style-type: none;
}

.single-footer-widget .social-links li {
    display: inline-block;
    margin-right: 10px;
}

.single-footer-widget .social-links li a {
    color: var(--text-color);
    border: 1px solid #525164;
    width: 35px;
    height: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    font-size: 14px;
}

.single-footer-widget .social-links li a.facebook {
    border: none;
    background-color: #4267b2;
}

.single-footer-widget .social-links li a.twitter {
    border: none;
    background-color: #38a1f3;
}

.single-footer-widget .social-links li a.instagram {
    border: none;
    background: radial-gradient(circle farthest-corner at 35% 90%,
            #fec564,
            transparent 50%),
        radial-gradient(circle farthest-corner at 0 140%,
            #fec564,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%,
            #5258cf,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%,
            #5258cf,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0,
            #893dc2,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%,
            #893dc2,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%,
            #d9317a,
            transparent),
        -webkit-gradient(linear, left top, left bottom, from(#6559ca), color-stop(30%, #bc318f), color-stop(50%, #e33f5f), color-stop(70%, #f77638), to(#fec66d));
    background: radial-gradient(circle farthest-corner at 35% 90%,
            #fec564,
            transparent 50%),
        radial-gradient(circle farthest-corner at 0 140%,
            #fec564,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 0 -25%,
            #5258cf,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 20% -50%,
            #5258cf,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 0,
            #893dc2,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 60% -20%,
            #893dc2,
            transparent 50%),
        radial-gradient(ellipse farthest-corner at 100% 100%,
            #d9317a,
            transparent),
        linear-gradient(#6559ca,
            #bc318f 30%,
            #e33f5f 50%,
            #f77638 70%,
            #fec66d 100%);
}

.single-footer-widget .social-links li a.linkedin {
    border: none;
    background-color: #0077b5;
}

.single-footer-widget .social-links li a:hover,
.single-footer-widget .social-links li a:focus {
    background-color: var(--scond-color);
    color: #ffffff;
    border-color: var(--scond-color);
}

.copyright-area {
    width: 100%;
    align-items: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    padding-bottom: 30px;
    margin: auto;
    text-align: center;
}

.socialicons {
    color: var(--text-color);
}

.section {
    display: flex;
}

.contact {
    justify-content: center;
    align-items: center;
}

.contact>div {
    margin-inline: auto;
}

.brands {
    text-align: center;
}

.copyright-area .logo a {
    display: inline-block;
}

.copyright-area ul {
    padding: 0;
    margin: 30px 0 20px;
    list-style-type: none;
}

.copyright-area h5 {
    color: var(--text-color);
}

.social {
    color: var(--scond-color);
}

.custom-icon {

    cursor: pointer;
    font-size: 18px;
}

.copyright-area ul li {
    position: relative;
    margin: 5px 15px;
    display: inline-block;
}

.copyright-area .social {
    display: flex;
    flex-direction: column;
    list-style-type: none;
}

.copyright-area .social {}

.copyright-area ul li::before {
    position: absolute;
    width: 6px;
    height: 6px;
    content: "";
    background: #a8a8ad;
    left: -18px;
    top: 50%;
    margin-top: -2px;
    border-radius: 50%;
}

.copyright-area ul li a {
    color: var(--text-color);
}

.copyright-area ul li a:hover,
.copyright-area ul li a:focus {
    color: var(--scond-color);
}

.copyright-area ul li:first-child {
    margin-left: 0;
}

.copyright-area ul li:first-child::before {
    display: none;
}

.copyright-area ul li:last-child {
    margin-right: 0;
}

.copyright-area p {
    color: var(--text-color);
}

/*================================================
Back To Top CSS
=================================================*/
.back-to-top {
    position: fixed;
    right: 1.5%;
    bottom: 15px;
    height: 15px;
    cursor: pointer;
    width: 40px;
    padding: 3px 5px;
    font-size: 10px;
    font-weight: bold;
    color: transparent;
    text-align: center;
    z-index: 3;
    outline: 0 !important;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background-color: var(--scond-color);
}

.back-to-top::before,
.back-to-top::after {
    content: "";
    position: absolute;
    left: 0;
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top: 0;
}

.back-to-top::before {
    top: -20px;
    z-index: 4;
    border-bottom: 20px solid var(--scond-color);
}

.back-to-top::after {
    bottom: 0;
    z-index: 5;
    border-bottom: 20px solid var(--scond-color);
}

.back-to-top:hover,
.back-to-top:focus {
    opacity: 1;
    text-decoration: none;
    height: 40px;
    color: #ffffff;
}

.back-to-top.show-back-to-top {
    display: block;
    bottom: 1.25%;
    visibility: visible;
}

:invalid {
    -webkit-box-shadow: none;
    box-shadow: none;
}

:-moz-submit-invalid {
    box-shadow: none;
}

:-moz-ui-invalid {
    box-shadow: none;
}

.modal-video {
    position: absolute !important;
}

.carsoul {
    direction: ltr;
}

.imgaeorgitem {
    height: 200px;
}

.img {
    cursor: pointer;
}

/*================================================
Calender CSS
=================================================*/

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 15, 15, 0.5);
    z-index: 1000;
}

.modalContainer {
    margin-block: 2rem;
    margin-inline: auto;

    width: 500px;
    top: 5;
    left: 0;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modalContainer .title {
    display: inline-block;
    text-align: start;
    margin-top: 10px;
}

.modalContainer .title img {
    border-radius: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.modalContainer .body {
    margin-top: 10px;
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: start;
}

.modalContainer .footer {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer .footer button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
}

#cancelBtn {
    background-color: crimson;
}

.plus-button {
    display: flex;
    justify-content: end;
}

.openModalBtn {
    padding-inline: 5px;
    background-color: var(--therd-color);
    color: white;
    cursor: pointer;
}

.schedule-area-calender .bg-image {
    background-image: url("../images/bg.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.calendrier {
    /* url("../images/bg.jpg"); */
    margin: auto;
    width: 80%;
    justify-content: space-between;
    display: flex;
    background: #fff;
    border-radius: 5px;
    border: 1px solid rgb(180, 180, 180);
}

.event {
    width: 50%;
    height: 80%;
}

.image-event {
    border-radius: 15px;

    height: 80%;
    position: relative;
}

.image-event>img {
    border-radius: 15px;

    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    filter: brightness(70%);
}

.event-title {
    border-radius: 15px;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100px;
    padding-inline-start: 3%;
    padding-top: 1%;
    bottom: 0px;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);
}

.event-title h3 {
    margin-block: 2%;

    color: white;
    /* Texte dans le h2 et le paragraphe */
}

.event-description {
    margin-top: 1%;
    /* display: -webkit-box;
    -webkit-line-clamp: 2;

    -webkit-box-orient: vertical;
    overflow: hidden; */
}

@media only screen and (min-width: 767px) {
    .next-events {
        padding: 1em;
        width: 60%;
        height: 30em;
    }

    .next-event-item {
        border: 1px solid #4f6995;
        border-radius: 1em;
        display: flex;
    }

    .next-event-item .next-event-item-image {
        width: 50%;
    }

    .next-event-item .next-event-item-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
    }
}

@media only screen and (max-width: 767px) {
    .next-events {
        padding: 1em;
        width: 100%;
        height: 30em;
    }

    .next-event-item {
        border: 1px solid #4f6995;
        border-radius: 1em;
        display: block;
    }

    .next-event-item .next-event-item-image {
        width: 100%;
    }

    .next-event-item .next-event-item-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
    }
}

.next-event-item-info {
    border-top-right-radius: 1em;
    border-bottom-right-radius: 1em;
    background-color: white;
    padding: 1em;
}

.next-event-item-info h5 {
    color: var(--scond-color);
}

.next-event-item-description p {}

.next-event-item-date {
    font-family: 'Roboto', sans-serif; 
    text-align: center;
    font-size: 20px;
    padding-bottom: 0.5em;
    color: #4f6995;
}

.scroll-bar {
    height: 30em;

    overflow: scroll;
}

/* Change the color of the scrollbar */
.scroll-bar::-webkit-scrollbar {
    width: 7px;
    /* Set the width of the scrollbar */
}

.scroll-bar::-webkit-scrollbar-thumb {
    background-color: rgb(211,
            211,
            211);
    /* Set the color of the scrollbar thumb */
    border-radius: 6px;
    /* Set the border radius of the scrollbar thumb */
}

.next-events-title {
    position: relative;

    z-index: 1;
}

.next-events-title .barevents {
    position: relative;
    width: 20px;
    height: 4px;
    background: var(--scond-color);
    display: block;
}

.next-events-title .barevents::after,
.next-events-title .barevents::before {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    content: "";
}

.next-events-title .barevents::after {
    width: 25px;
    background: var(--therd-color);
    right: -29px;
}

.next-events-title .barevents::before {
    background: var(--yellow-color);
    width: 30px;
    right: -63px;
}

.wrapper {
    background: #fff;
    border-radius: 10px;
}

.wrapper header {
    display: flex;

    padding: 25px 30px 10px;
    justify-content: space-between;
}

header .current-date {
    font-size: 1.20rem;
    font-weight: 500;
}

header .icons span {
    height: 40px;
    width: 40px;
    color: #878787;
    font-size: 1.9rem;
    margin: 0 1px;
    text-align: center;
    line-height: 38px;
    border-radius: 100%;
    cursor: pointer;
}

header .icons span:hover {
    color: var(--text-color);
}

.event-date {
    padding-top: 5px;

    margin-inline-start: 1%;
    color: white;
    bottom: 15px;
    position: relative;
    width: 30%;
}

.title .event-date {
    padding-top: 5px;

    margin-inline-start: 1%;
    color: black;
    bottom: 15px;
    position: relative;
    width: 30%;
}

header .icons span:last-child {
    margin-right: -10px;
}

.calendar {
    padding: 20px;
}

.calendar ul {
    display: flex;
    list-style: none;
    flex-wrap: wrap;
    text-align: center;
}

.calendar .days {
    margin-bottom: 20px;
}

.calendar .weeks li {
    font-weight: 500;
}

.calendar ul li {
    position: relative;
    width: calc(100% / 7);
}

.calendar .days li {
    cursor: pointer;
    margin-top: 30px;
}

.days li.inactive {
    color: #aaa;
}

.days li.active {
    color: #fff;
}

.calendar .days li::before {
    position: absolute;
    content: "";
    height: 40px;
    width: 40px;
    top: 50%;
    left: 50%;
    z-index: -1;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

.days li.active::before {
    background: var(--therd-color);

    opacity: 0;
    animation: scaleAnimation 4s ease-in-out forwards infinite;
    /* Répète indéfiniment */
}

@keyframes scaleAnimation {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.7;
        /* Réduit l'opacité à mi-chemin */
    }

    100% {
        opacity: 1;
    }
}

.days li.isEvent {
    border-bottom: 1px solid var(--therd-color);
    z-index: 0;
}

.days li:hover .days li {
    color: white;
}

.hover-container {
    display: none;
}

.days li {
    z-index: 0;
}

.hover-container {
    display: none;
}

.days li:hover .hover-container {
    border: 1px solid gray;
    border-radius: 5px;
    position: absolute;
    top: 60%;
    left: 60%;
    background-color: white;
    display: inline-block;
    white-space: nowrap;
    padding: 4px;
    z-index: 5;
    color: var(--yellow-color);
}

@media (max-width: 900px) {
    .calendrier {
        margin: auto;

        justify-content: space-between;
        display: block;
        background: #fff;
        border-radius: 10px;
    }

    .wrapper {
        background: #fff;
        border-radius: 10px;
    }
}

/* pratner*/



.nospartner {

    background: #ffffff;
    margin-bottom: 30%;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.06);
}

.partner-image {}

.partner-image>img {
    filter: grayscale(100%);
}

.partner-image :hover {
    filter: none;
}




@media (max-width: 768px) {
    .info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.lang-option {
    font-weight: bold;
    color: #275470;
}

.lang-dropdown {
    font-weight: bold;
    color: #275470;
}

.feature h3 {
    color: var(--scond-color);
}

.svg {
    fill: var(--scond-color);
    width: 45px;
}

.contact-box:hover .svg {
    cursor: pointer;
    fill: white;
}

/*================================================
Teams CSS
=================================================*/

.teams .container {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 40px 0;
}

.teams .container .card {
    position: relative;
    width: 300px;
    height: 400px;

    margin: 20px;
    box-shadow: 0 15 xx 35 x rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.teams .container .card .content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    transition: 0.5s;
}

.teams .container .content {
    opacity: 1;
    transform: translateY(-20px);
}

.teams .container .card .content .imgBx {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    border: 10px solid rgba(0, 0, 0, 0.25);
}

.teams .container .card .content .imgBx img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: initial;
}

.teams h3 {
    color: var(--text-color);
    font-weight: 500;
    text-align: center;
    margin: 20px 0 10px;
    line-height: 1.1em;
}

.teams h5 {
    font-weight: 500;
    text-transform: initial;
    text-align: center;
}

.teams .container .card .sci {
    position: absolute;
    bottom: 30px;
    display: flex;
    padding-top: 10px;
}

.teams .container .card .sci li {
    list-style: none;
    margin: 0 10px;
    transform: translateY(40px);
    transition: 0.5s;
    opacity: 0;
    transition-delay: calc(0.1s * var(--i));
}

.teams .container .card:hover .sci li {
    transform: translateY(0px);
    opacity: 1;
    padding-top: 10px;
}

.teams .container .card:hover .sci li a {
    color: var(--therd-color);
}

/*================================================
Why chose use home page CSS
=================================================*/

.counter {
    color: red;
    text-align: center;
    font-size: 2em;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
}

.counter.active {
    opacity: 1;
}

.why-choose-us {
   
    
   align-items: center;
   justify-content: center;
    margin: auto;
    width: 100%;
    padding-top: 5rem;
}

.why-choose-us-cent {
    margin: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.single-box {
    padding: 5rem;
}

/* Container styles */
.why-choose-us-cent.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    box-sizing: border-box;
}

/* Grid item styles */
.grid-item {
    flex: 1 1 100%;
    max-width: 100%;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
}

/* Box styles */
.box {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Typography styles */
.count {
    font-size: .92rem;
    font-family: 'Bubblegum', cursive;

}

.description {
    font-family: 'Bubblegum', cursive;
    font-size: 16px;
 
   
}

/* Responsive adjustments */
@media (min-width: 768px) {
    .grid-item {
        flex: 1 1 45%;
        max-width: 45%;
    }
}

@media (min-width: 1024px) {
    .grid-item {
        flex: 1 1 30%;
        max-width: 30%;
    }
}

.single-box .content {
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

.single-box .content .icon {
    font-size: 45px;
    background: var(--therd-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.single-box .content h3 {
    font-size: 26px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
}

.single-box .content p {
    color: black;
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
}

.single-box:hover .content,
.single-box:focus .content {
    bottom: 35px;
}

.single-box:hover .content p,
.single-box:focus .content p {
    margin-bottom: 25px;
}

/*================================================
Why chose use about page CSS
=================================================*/

.why-choose-us-cent-tow>div {
    display: flex;
}

@media only screen and (max-width: 767px) {
    .why-choose-us-cent-tow>div {
        display: block;
    }
}

.single-box-tow .content-tow {
    text-align: start;
    width: 100%;
    display: flex;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

.single-box-tow .content-tow .icon-tow {
    font-size: 45px;
    color: var(--scond-color);
}

.single-box-tow .content-tow h3 {
    font-size: 26px;
    color: black;
    margin-top: 15px;
    margin-bottom: 15px;
}

.single-box-tow .content-tow p {
    color: var(--text-color);
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-size: 1.3rem;
}

.why-choose-us-cent-tow div div:nth-child(2) .single-box-tow {
    border-top: none;
    border-left: 1px solid rgba(32, 29, 29, 0.2);
}

.why-choose-us-cent-tow div:nth-child(2) .single-box-tow {
    margin-top: 1em;
    border-top: 1px solid rgba(32, 29, 29, 0.2);
}

.single-box-tow .content-tow::before {
    border: 10px solid rgba(104, 89, 222, 0.03);
}

/*================================================
Why chose use about page CSS
=================================================*/

.why-choose-us-values::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

    opacity: 0.88;
}

.why-choose-us-values {
    display: flex;
    flex-wrap: wrap;

    position: relative;
}

.why-choose-us-cent-values {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
}

.single-box-values {
    border: 1px solid var(--scond-color);
}

/* Media query for screens smaller than 768px */
@media screen and (min-width: 995px) {
    .why-choose-us-cent-values {
        display: flex;
        flex-wrap: wrap;
        /* Allow items to wrap onto the next line */
        justify-content: space-around;
        /* Adjust as needed */
        margin: auto;
    }

    .single-box-values {
        width: 105%;
        /* Set the width to 45% to allow two items per line with some spacing */
        margin-bottom: 20px;
        /* Add some spacing between the items */
    }

    .values .col-lg-3 {
        flex: 0 0 calc(33.33% - 10px);

        box-sizing: border-box;
    }
}

/* Media query for screens between 768px and 991px */
@media screen and (min-width: 768px) and (max-width: 995px) {
    .why-choose-us-cent-values {
        display: flex;
        flex-wrap: wrap;
        /* Allow items to wrap onto the next line */
        justify-content: space-around;
        /* Adjust as needed */
        margin: auto;
    }

    .single-box-values {
        width: 105%;
        /* Set the width to 45% to allow two items per line with some spacing */
        margin-bottom: 20px;
        /* Add some spacing between the items */
    }

    .values .col-lg-3 {
        flex: 0 0 calc(50% - 10px);

        box-sizing: border-box;
    }
}

/* Media query for screens 992px and larger */
@media screen and (max-width: 768px) {
    .why-choose-us-cent-values {
        display: block;

        justify-content: space-around;

        align-items: start;
    }

    .single-box-values {
        width: 100%;
        /* Set the width to 45% to allow two items per line with some spacing */
        margin-bottom: 20px;
        /* Add some spacing between the items */
    }

    .values .col-sm-6 {
        flex: 0 0 calc(100% - 20px);
        background-color: red;

        box-sizing: border-box;
    }
}

.single-box-values .content-values {
    text-align: center;
    padding-left: 25px;
    padding-right: 25px;
}

.video-backgroundtow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(50%);
    z-index: -1;
}

.why-choose-us-values .slideshow {
    z-index: -1;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    filter: brightness(50%);

    z-index: 0;
}

.values .col-lg-3:first-child .single-box-values {
    border-left: none;
}

.values .single-box-values {
    background-color: var(--scond-color);
    margin-top: 85px;
    margin: 1px;
    position: relative;
    z-index: 1;

    text-align: center;
    height: 265px;
    margin-bottom: 85px;
}

.values .single-box-values .content-values {
    position: absolute;
    padding-left: 25px;
    padding-right: 25px;
    bottom: 0;
    left: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    width: 100%;
    text-align: center;
    align-items: center;
    height: 250px;
}

.values .single-box-values .content-values .icon-values {
    font-size: 45px;
    color: #ffffff;
}

.values .single-box-values .content-values h3 {
    font-size: 26px;
    color: #ffffff;
    margin-top: 15px;
    margin-bottom: 15px;
}

.values .single-box-values .content-values p {
    color: #ffffff;
    margin-bottom: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    text-align: center;
    font-size: 20px;
}

.values .single-box-values:hover .content-values,
.single-box-values:focus .content-values {
    bottom: 25px;
}

.values .single-box-values:hover .content-values p,
.single-box-values:focus .content-values p {
    margin-bottom: 25px;
}

.values {
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.values::after {}

.value-item {
    margin: 10px;
    padding: 2rem;
    border: 1px solid rgb(202, 202, 202);
    width: 100%;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    height: 42vh;
    width: 60vh;
    position: relative;
    justify-content: center;
    align-items: center;
}

.value-item h3 {
    text-align: center;
    color: #154da1;
}

.value-item p {
    font-size: large;
    font-weight: 400;
    text-align: center;
    color: #000000;
}

.value-item img {
    height: 35%;
    object-fit: contain;
    vertical-align: middle;
    transition: transform 0.5s ease-in-out;
}

.value-item:hover img {
    transform: scale(1.2);
}

/*================================================
language switch CSS
=================================================*/

.language-switch {
    position: relative;
}

.lang-dropdown {
    border: none;

    font-size: 16px;
    color: #333;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.lang-dropdown::after {
    content: "\25BE";
    margin-left: 10px;
}

.lang-dropdown:hover {
    background-color: #f9f9f9;
}

.lang-dropdown option {
    padding: 8px 20px;
    font-size: 16px;
}

/* Table */
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
}

th {
    background-color: #f2f2f2;
}

/*-----------------------------------------------------------
    Theme Name: Doob | Simple Agency HTML Template
    Description: Simple Agency HTML Template
    Author: Graphberry
    Author URI: https://www.graphberry.com/
    Version: 1.0
-------------------------------------------------------------*/

/*-----------------------------------------------------------
    1  General Styles
    2  Typography
    3  Buttons & Links 
    4  Header
    5  Services
    6  About  
    7  Portfolio
    8  Blog
    9  Contact
    10 Footer
    11 Responsive 
-------------------------------------------------------------*/

/*-----------------------------------------------------------
    1. General Styles 
-------------------------------------------------------------*/
#home {}

* {
    margin: 0;
    padding: 0;
}

html {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

a {
    font-weight: 600;
    color: var(--scond-color);
    text-decoration: none;
    letter-spacing: 1px;
    position: relative;
}

a:focus,
a:hover {
    color: rgba(44, 44, 44, 0.8);
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 600;
    letter-spacing: 1px;
}

:active,
:focus {
    outline: none !important;
}

/*-----------------------------------------------------------
    2. Typography 
  ------------------------------------------------------------*/
body {
    color: var(--scond-color);
    font-family: 'Roboto', sans-serif; 
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;
    transition: opacity 1s;
    min-width: 330px;
}

h1,
.h1 {
    font-size: 4.4rem;
    margin-bottom: 20px;
}

h2,
.h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 50px;
}

.about h2 {
    margin-bottom: 25px;
}

h3,
.h3 {
    font-size: 1.9rem;
    line-height: 2.5rem;
    margin-bottom: 15px;
}

h4,
.h4 {
    font-size: 24px;
}

h5,
.h5 {
    font-size: 1rem;
    color: #597931;
}

h6,
.h6 {
    font-size: 1rem;
    margin-bottom: 10px;
}

.about p {
    color: #314584;
}

p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #6e757a;
    margin-bottom: 15px;
    letter-spacing: 0.025em;
}

/*-----------------------------------------------------------
    3. Buttons & Links
  -------------------------------------------------------------*/
.contact-btn button {
    width: 200px;
    height: 60px;
    border: 0px;
    -webkit-box-shadow: 0px 4px 6px 0px rgba(30, 32, 33, 0.07);
    -moz-box-shadow: 0px 4px 6px 0px rgba(30, 32, 33, 0.07);
    box-shadow: 0px 4px 6px 0px rgba(30, 32, 33, 0.07);
    background-color: #fff;
    border-radius: 3px;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif; 
    font-weight: 600;
    color: #ff3946;
}

.contact-btn i {
    font-size: 2rem;
    width: 100px;
    height: 100px;
    color: #ff3946;
}

.hero-btns a {
    background-color: var(--therd-color);
    border: 2px solid var(--therd-color);
    color: #fff;
    padding: 8px 35px;
    font-size: 1rem;
    border-radius: 30px;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(255, 57, 70, 0.15);
    -moz-box-shadow: 0px 4px 5px 0px rgba(255, 57, 70, 0.15);
    box-shadow: 0px 4px 5px 0px rgba(255, 57, 70, 0.15);
}

.hero-btns a:hover {
    background-color: #fff;
    border: 2px solid var(--scond-color);
    color: #314584;

    font-size: 1rem;
    border-radius: 30px;
    transition: all 0.3s ease;
}

.navbar-light .navbar-toggler {
    color: var(--scond-color);

    border: none;
}

.navbar-toggler {
    padding: 0.5rem 1.2rem 0.25rem 0rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.interactive-menu-button {
    position: relative;
}

.interactive-menu-button a {
    width: 20px;
    height: 20px;
    display: block;
    position: relative;
}

.interactive-menu-button a span {
    height: 4px;
    position: absolute;
    background: #314584;
    text-indent: -999em;
    width: 100%;
    top: 6px;
    transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);
}

.interactive-menu-button a span:before,
.interactive-menu-button a span:after {
    content: "";
    height: 4px;
    background: #314584;
    width: 100%;
    position: absolute;
    top: -6px;
    left: 0;
    transition: all 0.5s cubic-bezier(0, 0.275, 0.125, 1);
}

.interactive-menu-button a span:after {
    top: 6px;
}

.interactive-menu-button a.active span {
    background: transparent;
}

.interactive-menu-button a.active span:before {
    transform: translateY(6px) translateX(1px) rotate(45deg);
}

.interactive-menu-button a.active span:after {
    transform: translateY(-6px) translateX(1px) rotate(-45deg);
}

.navbar-nav .dropdown-menu {
    text-align: center;
    border: 0;
    background-color: #314584;
}

.navbar-nav .dropdown-menu a {
    color: #fff;
    cursor: pointer;
    font-size: 1.3rem;
    margin: 10px 0px;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #ff3946 !important;
    text-decoration: none;
    background-color: #314584;
}

.service-btn a {
    color: #fff !important;
    font-size: 0.8rem !important;
}

.service-btn a:hover {
    color: #ff3946 !important;
}

.more-btn {
    text-align: center;
    margin: 15px 0px 100px 0px;
}

.more-btn a {
    font-size: 1.2rem;
    color: #ff3946 !important;
    cursor: pointer;
    padding: 20px 30px;
}

.more-btn a:hover {
    color: #314584 !important;
}

.contact-btn {
    text-align: center;
    margin-top: 70px;
}

.contact-btn a {
    color: #fff !important;
}

.contact-btn a:hover {
    color: #314584 !important;
}

/*-----------------------------------------------------------
    4. Header
  -------------------------------------------------------------*/
.navbar-light .navbar-nav .nav-link {
    color: rgb(44, 44, 44);
    font-size: 1rem;
}

/* .hero-btns {
    margin-bottom: 350px;
} */
.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: var(--scond-color);
}

.navbar {}

.navbar-light .navbar-brand {
    margin-right: 10%;
}

.nav-item {
    padding: 0 10px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: var(--scond-color);
}

.hero img {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -9;
    margin-top: 30px;
    width: 50%;
}

.hero h1 {
    margin-top: 250px;
}

.hero p {
    margin-bottom: 50px;
}

/*-----------------------------------------------------------
    5. Services
  -------------------------------------------------------------*/
.services {
    padding: 50px 0px;
}

.services p {
    font-size: 1rem;
    color: #314584;
    line-height: 1.5rem;
}

.side-img img {
    position: absolute;
    left: -20px;
}

.side2-img img {
    position: absolute;
    right: -20px;
    top: 1300px;
}

.service-box {
    background-color: #fff;
    padding: 40px 45px;
    margin: 10px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(28, 31, 33, 0.15);
    -moz-box-shadow: 0px 3px 7px 0px rgba(28, 31, 33, 0.15);
    box-shadow: 0px 3px 7px 0px rgba(28, 31, 33, 0.15);
    min-height: 400px;
}

.service-box img {
    margin-bottom: 30px;
}

/*-----------------------------------------------------------
    6. About
  -------------------------------------------------------------*/
.about {
    margin-bottom: 150px;
}

/*-----------------------------------------------------------
   7. Portfolio
  -------------------------------------------------------------*/
.portfolio-aside img {
    margin-top: 20px;
    position: absolute;
    left: -20px;
}

.work-box {
    margin-bottom: 30px;
}

.work-box img {
    width: 370px;
    height: auto;
}

/* Portfolio Gallery Hover */
.photobox {
    display: inline-block;
}

.photobox__previewbox {
    position: relative;
    overflow: hidden;
}

.photobox__preview {
    display: block;
    max-width: 100%;
}

.photobox__previewbox:before {
    content: "";
}

.photobox_type10 .photobox__previewbox:before {
    width: 0;
    height: 0;
    padding: 25%;
    background-color: #314584;
    opacity: 0.8;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: transform calc(var(--photoboxAnimationDuration, 0.8s) / 2) ease;
    will-change: transform;
    transform: translate(-50%, -50%) scale(0);
}

.photobox_type10:hover .photobox__previewbox:before {
    transform: translate(-50%, -50%) scale(4);
    transition-duration: var(--photoboxAnimationDuration, 0.8s);
}

.photobox_type10 .photobox__label {
    width: 95%;
    text-align: center;
    opacity: 0;
    transition: opacity calc(var(--photoboxAnimationDuration, 0.8s) / 4) cubic-bezier(0.71, 0.05, 0.29, 0.9),
        transform calc(var(--photoboxAnimationDuration, 0.8s) / 4) cubic-bezier(0.71, 0.05, 0.29, 0.9);
    will-change: opacity, transform;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 200%);
    z-index: 3;
    color: #fff;
    font-family: 'Roboto', sans-serif; 
    font-weight: 600;
    font-size: 1.1rem;
}

.photobox_type10:hover .photobox__label {
    opacity: 1;
    transform: translate(-50%, -50%);
    transition-duration: calc(var(--photoboxAnimationDuration, 0.8s) / 2);
    transition-delay: calc(var(--photoboxAnimationDuration, 0.8s) / 4);
}

.hide {
    display: none;
    transition: all 0.3s ease;
}

/*-----------------------------------------------------------
    8. Blog
  -------------------------------------------------------------*/
.blog-aside img {
    position: absolute;
    right: -20px;
    margin-top: 200px;
}

.blog {
    padding: 25px 0px;
    margin-bottom: 100px;
}

.blog-box {
    background-color: #fff;
    min-height: 330px;
    max-width: 370px;
    padding: 50px 25px;
    -webkit-box-shadow: 0px 3px 7px 0px rgba(28, 31, 33, 0.15);
    -moz-box-shadow: 0px 3px 7px 0px rgba(28, 31, 33, 0.15);
    box-shadow: 0px 3px 7px 0px rgba(28, 31, 33, 0.15);
    margin: 10px;
}

.blog-box p:nth-child(2) {
    font-size: 0.9rem;
    color: #6e757a !important;
}

.blog-box p:nth-child(3) {
    font-size: 1.2rem;
    color: #314584;
}

.slick-prev {
    position: absolute;
    right: 60px;
    top: -50px;
}

.slick-next {
    position: absolute;
    right: 10px;
    top: -50px;
}

.blog-slider button {
    font-size: 3rem;
    border: none;
    background-color: #fff;
    color: #bbbbbc;
}

.blog-slider button:hover {
    color: #314584;
    transition: all 0.3s ease;
}

.blog-first {
    background-color: #ff3946;
}

.blog-first h6 {
    color: #fff;
}

.blog-first p:nth-child(2) {
    color: #fff !important;
}

.blog-first p:nth-child(3) {
    color: #fff;
}

.blog-slider {
    cursor: pointer;
}

.hide-blog {
    display: none;
}

#blog-btn {
    color: #ff3946;
    font-size: 1.2rem;
    font-family: 'Roboto', sans-serif; 
    font-weight: 600;
    background-color: #fff;
    border: none;
    margin-top: 20px;
}

#blog-btn:hover {
    color: #314584;
}

.hide-me {
    display: none;
}

/*-----------------------------------------------------------
    9. Contact
  -------------------------------------------------------------*/
.contact {
    max-height: 550px;
}

.email input {
    width: 100%;
    height: 60px;
    background-color: #f4fafe;
    border: none;
    padding: 0px 20px;
    color: #314584;
    font-weight: 600;
    margin-bottom: 15px;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #314584;
    font-weight: 600;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #314584;
    font-weight: 600;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #314584;
    font-weight: 600;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #314584;
    font-weight: 600;
}

textarea {
    background-color: #f4fafe;
    border: none;
    width: 100%;
    padding: 20px;
    margin-top: 15px;
    color: #314584;
    font-weight: 600;
}

/*-----------------------------------------------------------
    10. Footer
  -------------------------------------------------------------*/
footer {
    background-color: #314584;
    width: 100%;
    min-height: 400px;
    text-align: center;
    padding: 70px 0px 0px 0px;
}

footer h5 {
    color: #fff;
    padding-bottom: 10px;
}

footer h3 {
    color: #ff3946;
    font-size: 3rem;
    margin-bottom: 30px;
}

.contact-nav li {
    display: inline;
    color: #fff;
}

.contact-nav a {
    color: #fff;
    font-size: 1.1rem;
    padding: 20px 20px;
}

.contact-nav a:hover {
    color: #ff3946;
}

footer h6 {
    color: #fff;
    font-size: 0.9rem;
    font-weight: 400;
    margin-top: 50px;
    margin-bottom: 20px;
}

.social li {
    display: inline;
}

.social li a {
    font-size: 2.7rem;
    color: #fff;
    margin: 0px 30px;
}

.social li a:hover {
    color: #ff3946;
    transition: all 0.3s ease;
}

/*-----------------------------------------------------------
    11. Responsive
  -------------------------------------------------------------*/

/* .hero-container .image-container img { 
 
    object-fit: contain;
    height: 100%;
width: 80%;
} */

.hero-container {
    text-align: start;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    height: 65vh;
}

.image-container {
    width: 50vw;
    height: 50vw;
    max-width: 50%;
    max-height: 100%;
    overflow: hidden;
    position: relative;
    /* transform: rotate(45deg); */
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 1200px) {
    .image-container {
        width: 40vw;
        height: 40vw;
    }

    .text-container {
        width: 30rem;
    }

    .text-container .text {
        width: 100%;
        margin: auto;
        align-items: center;
    }
}

@media (max-width: 992px) {
    .image-container {
        width: 40vw;
        height: 40vw;
    }

    .text-container {
        

        width: 30rem;
    }

    .text-container .text {
        width: 100%;
        margin: auto;
        align-items: center;
    }
}

@media (max-width: 768px) {
    .image-container {
        width: 40vw;
        height: 40vw;
    }

    .text-container {
        width: 30rem;
    }

    .text-container .text {
        width: 100%;
        margin: auto;
        align-items: center;
    }
}

.image-overlay {
    position: absolute;
    /* Positionner la superposition par rapport à son conteneur parent */
    top: 50%;
    /* Positionner la superposition au centre verticalement */
    left: -6%;
    /* Positionner la superposition à 55% du côté gauche pour laisser de l'espace à l'image */
    transform: translate(-50%, -50%);
    /* Centrer la superposition précisément */
    padding: 20px;
    /* Ajouter un peu de padding autour du texte */
}

@media (max-width: 1199px) {
    .hero-container {
        text-align: center;
        /* Permet de redimensionner correctement sur les petits écrans */
    }

    .text-container {
        margin: auto;
        width: 30rem;
    }

    .hero-container img {
        margin-top: 20px;
    }
}

@media (max-width: 1700px) {
    /* .hero-btns {
        margin-bottom: 200px;
    } */
}

@media (max-width: 1700px) {
    /* .hero-btns {
        margin-bottom: 200px;
    } */
}

@media (max-width: 1500px) {
    .hero-container img {}
}

@media (max-width: 1350px) {
    /* .hero-btns {
        margin-bottom: 140px;
    } */
}

@media (max-width: 991px) {
    .navbar {}

    /* .hero-container img {
        display: none;
    } */
    .hero-container {
        text-align: center;
    }

    .hero-container h2 {
        /* margin-top: 80px; */
    }

    .navbar-collapse {
        text-align: center;
        margin-top: 50px;
    }

    .navbar-light .navbar-nav .nav-link {
        font-size: 2rem;
    }

    .contact-btn {
        display: none;
    }

    .hero-btns {
        /* margin-bottom: 70px; */
        width: 50%;
    }
    @media (max-width: 768px) {
    
        .hero-btns {
            /* margin-bottom: 70px; */
            width: 100%;
        }
    }
    .text-container {
        margin: auto;
        width: 30rem;
    }

    .service-txt {
        text-align: center;
    }

    .about img {
        margin-bottom: 70px;
    }

    .about {
        text-align: center;
    }

    .portfolio {
        text-align: center;
    }

    .blog {
        text-align: center;
    }

    .blog-box {
        margin: 0 auto;
        margin-bottom: 25px;
    }

    .contact {
        text-align: center;
    }
}

@media (max-width: 1100px) {
    .text-container {
        margin: auto;
        width: 100%;
    }

    .image-container {
        display: none;
    }

    .text-container .text {
        margin-inline: auto;
        width: 100%;
    }

    .hero-container h2 {
        /* font-size: 3.5rem; */
    }

    .service-box {
        padding: 20px 25px;
        margin: 0px;
    }

    .contact-nav a {
        font-size: 1rem;
        padding: 20px 5px;
    }
}

@media (max-width: 575px) {
    .text-container { margin: auto;

        width: 100%;}

    .service-box {
        padding: 20px 25px;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 40px;
    }

    footer h3 {
        font-size: 2.5rem;
    }
}

@media (max-width: 454px) {
    .hero-container {
        display: block;
    }

    .text-container {
        margin: auto;

        width: 100%;
    }

    .image-container {
        display: none;
    }


    .hero-container h1 {
        font-size: 3rem;
        margin-top: 50px;
    }

    .hero-container p {
        font-size: 0.8rem;
    }

    .hero-btns {}

    .hero-btns a {
        font-size: 0.8rem;
        display: block;
        width: 70%;
        margin: 0 auto;
    }

    footer h3 {
        line-height: 2.6rem;
    }

    .contact-nav li {
        display: block;
    }

    .social li {
        display: block;
    }
}

/* .container {
    max-width: 1200px;
    margin: 0 auto;
  } */

.section-title {
    text-align: center;
    margin-bottom: 50px;
}

.bar {
    width: 50px;
    height: 3px;
    background-color: #007bff;
    margin: 0 auto 20px;
}

.teams {
    padding: 50px 0;
}

.single-team {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    /* Ajout de l'ombre portée */
}

.team-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
    margin-right: 20px;
}

.team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-info h5 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.team-info h6 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .single-team {
        flex-direction: column;
    }

    .team-image {
        margin-right: 0;
        margin-bottom: 20px;
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.section-title {
    margin-bottom: 50px;
}

.bar {
    width: 50px;
    height: 3px;
    background-color: #007bff;
    margin: 0 auto 20px;
}

.teams {
    padding: 50px 0;
}

.team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.single-team {
    width: calc(50% - 20px);
    /* 50% de largeur moins les marges */
    margin-bottom: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Ombre portée */
}

.team-info {
    padding: 20px;
}

.team-image {
    width: 150px;
    height: 150px;
    overflow: hidden;
}

.team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-info h5 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.team-info h6 {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .single-team {
        width: 100%;
        /* Pleine largeur sur les écrans plus petits */
    }
}

.container-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1200px;
    margin-block: 2rem;
    gap: 2rem;
}

img {
    max-width: 100%;
    display: block;
    object-fit: cover;
}

.card {
    display: flex;
    flex-direction: column;
    width: clamp(20rem, calc(20rem + 2vw), 22rem);
    overflow: hidden;
    box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1);
    border-radius: 1em;
    background: #ece9e6;
    background: linear-gradient(to right, #ffffff, #ece9e6);
}

.card__body {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: 20rem;
}

.blogs-page h1,
.blogs-page span {
    color: var(--scond-color);
}

.card-description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.card-description p {
    margin: 0;
}

.tag {
    align-self: flex-start;
    padding: 0.25em 0.75em;
    border-radius: 1em;
    font-size: 0.75rem;
}

.tag+.tag {
    margin-left: 0.5em;
}

.tag-blue {
    background: #56ccf2;
    background: linear-gradient(to bottom, #2f80ed, #56ccf2);
    color: #fafafa;
}

.tag-brown {
    background: #d1913c;
    background: linear-gradient(to bottom, #ffd194, #d1913c);
    color: #fafafa;
}

.tag-red {
    background: linear-gradient(to bottom, var(--scond-color), #ffd194);
    color: #fafafa;
}

.card__body h4 {
    font-size: 1.5rem;
    text-transform: capitalize;
}

.card__footer {
    display: flex;
    padding: 1rem;
    margin-top: auto;
}

.user {
    display: flex;
    gap: 0.5rem;
}

.user__image {
    border-radius: 50%;
}

.user__info>small {
    color: #666;
}

.contact-us h3 {
    color: var(--scond-color);
}

.circle-content{

margin: 5rem;

}


.circle-container {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 50vh;   

    width: 450px;
}
.items-about{
    display: none;
}
@media (max-width: 1180px) {
    .circle-content{
        display: none;
        
        }


}

@media (max-width: 1180px) {
    .items-about{
        display: flex;
       
        
    }
    
.about-item{
    width: 180px;
    height: 100px;   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

}
@media (max-width: 767px) {
    .items-about{
        display: flex;
        flex-direction: column;

        
    }
    
.about-item{
    width: 180px;
    height: 100px;   display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 15px;
}

}
.rotating-circle {
    position: relative;
    width: 290px;
    height:280px;
    border-radius: 50%;
   /* border: 0.5px  double  #f5f5f5; */
    display: flex;
    justify-content: space-evenly;
    align-items:flex-end;
    /* animation: rotateCircle 25s linear infinite; */
}

.circle-item {
    position: absolute;
    width: 180px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* animation: rotateItem 25s linear infinite; */

}

.circle-item p {
    margin-top: 8px;
    font-size: 16px;
 
}

.circle-item svg {
    font-size: 24px;
}
.item-icon{
    border: 1px solid var(    --scond-color);
    background-color: var(    --scond-color);
    border-radius: 50%;
    padding: 10px;
}
/* Positionnement initial des éléments autour du cercle */
.item1 {
    top: 52%;
    left: 66%;
    transform: translate(0, -50%);
    margin-right: 50px;

}

.item2 {
    margin-top: 15px;
    top: 0%;
    right: 15%;
    transform: translate(0, -50%);    margin-left: 50px;
 
}

.item3 {
    top: 50%;
    left: -30%;

    transform: translate(0%, -50%);
    margin-bottom: 50px;

}
.icons{
    color: white;
}
/* Animation pour faire tourner le cercle */
@keyframes rotateCircle {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Animation pour garder les éléments fixes et lisibles */
@keyframes rotateItem {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}